import React, { useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { BsExclamationCircle } from "react-icons/bs";

import {
  StyledModal,
  StyledModalDialog,
  StyledModalContent,
  StyledCloseOutside,
  StyledModalClose,
} from "../../../theme";
import { useToast } from "../../../hook/useToast";
import { Text, ButtonCom } from "../../common";

export const ToastCom = ({ isCloseBtn = false, ...props }) => {
  const { createToast_data, showToast, hideToast } = useToast();

  useEffect(() => {
    if (!createToast_data?.alway && createToast_data?.toastModalVisible) {
      setTimeout(() => {
        hideToast();
      }, createToast_data?.timer || 5000);
    }
  }, [createToast_data?.toastModalVisible]);

  return (
    <StyledModal
      className={`fade ${
        createToast_data?.toastModalVisible ? "show" : "hide"
      }`}
      {...props}
    >
      <StyledCloseOutside onClick={hideToast} />
      <StyledModalDialog modalWidth={3} {...props}>
        {isCloseBtn && (
          <StyledModalClose onClick={hideToast}>
            <RiCloseFill />
          </StyledModalClose>
        )}
        {createToast_data?.render ? (
          createToast_data?.render
        ) : (
          <StyledModalContent {...props}>
            {createToast_data?.title && (
              <Text
                size="sm"
                weight="lg"
                {...createToast_data?.titleStyle}
                className="d-flex flex-column justify-content-center align-items-center error-message-title"
              >
                <BsExclamationCircle
                  size={40}
                  style={{ marginBottom: "10px" }}
                />
                <Text as="span">{createToast_data?.title}</Text>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <ButtonCom
                    className="d-flex align-items-center justify-content-center"
                    btnBorderRadius="xxxs"
                    bgcolor="primary"
                    color="light"
                    text="Ok"
                    onClick={() => hideToast()}
                  />
                </div>
              </Text>
            )}
            {createToast_data?.desc && (
              <Text size="xs" {...createToast_data?.descStyle}>
                {createToast_data?.desc}
              </Text>
            )}
            {/* {!createToast_data?.title && !createToast_data?.desc && <Text size="xs">Required Text Field</Text>} */}
          </StyledModalContent>
        )}
      </StyledModalDialog>
    </StyledModal>
  );
};
