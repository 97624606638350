import React from "react";

import { StyledCardContainer } from "../../../theme";
import { Text } from "../typo";
export const CardCom = ({ children, ...props }) => {
  return (
    <StyledCardContainer {...props}>
      {children ? children : <Text weight="lg" textAlign="center"></Text>}
    </StyledCardContainer>
  );
};
