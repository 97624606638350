import styled from "styled-components";
import { fontSize, fontWeight, fontFamily, color } from "../../attribute";

export const StyledText = styled.p`
  line-height: ${(props) => fontSize[props?.lineHeight] || props?.lineHeight}px;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: calc(
      ${fontSize.sm}px +
        (
          ${(props) => fontSize[props?.size] || props.size || fontSize.md} -
            ${fontSize.sm}
        ) * ((100vw - 100px) / (1023 - 100))
    );
  }
  @media (min-width: 1024px) {
    font-size: ${(props) =>
      fontSize[props?.size] || props.size || fontSize.md}px;
  }
  font-weight: ${(props) =>
    fontWeight[props?.weight] || props?.weight || fontWeight.sm};
  color: ${(props) => color[props?.color] || props?.color || color.text};
  text-align: ${(props) => props.textAlign || "left"};
  font-family: ${(props) => fontFamily[props?.family] || fontFamily?.Poppins};
  font-style: ${(props) => props?.fontStyle};
  margin: 0;
  text-decoration: ${(props) =>
    props?.textDecoration || (props?.link ? "underline" : "auto")};
  text-decoration-color: ${(props) =>
    props?.link ? color[props?.color] || props?.color || color.text : "auto"};
`;
