import styled from "styled-components";

export const StyledSearchResult = styled.div`
  section{
    .search-sec{
      @media (min-width: 1201px) {
        width: 70%;
        margin: auto;
      }
    }
  }
  hr {
    margin-block: 25px;
  }
  .filter-wrap {
    .radio-con {
      .field-wrapper {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .search-result-sec {
    margin-block: 50px;
    h1.title {
      margin-bottom: 10px;
    }
    .booklist-sec {
      margin-bottom: 30px;
      .row {
        row-gap: 30px;
      }
    }
  }
  .no-result {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    gap: 2rem;
    img {
      width: 100px;
    }
  }
`;
