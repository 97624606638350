import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { languages } from "../key/LangKey";
import other from "../../services/actions/other";

export const useHandleOther = (props) => {
  const other_state = useSelector(state => state.other)
  const dictionary_data = other_state.dictionary_data
  const langStore = other_state.langStore
  const dispatch = useDispatch()

  const getUiTranslate = (
    dict = { loading: { en: 'Loading', my: 'လုပ်ဆောင်နေသည်' } },
    entry,
    lang,
    def
  ) => {
    if (dict) {
      return dict?.[entry]?.[lang] || def || entry
    }
  }

  const translate = (key, def) => {
    return getUiTranslate(dictionary_data, key, langStore?.code, def);
  };

  const handleChangeLanguage = async (data) => {
    await dispatch(other.setLangStore(data));
  };

  return {
    ...other_state,
    other,
    languages,
    langStore,
    translate,
    handleChangeLanguage
  };
};
