import styled from "styled-components";
import { color } from "../../attribute";

export const StyledBookCard = styled.div`
  cursor: pointer;
  height: 100%;
  .card {
    height: 100%;
    padding: 16px 15px;
  }
  .wrap {
    gap: 12px;
    .book-image {
      max-width: 170px;
      height: max-content;
      @media (max-width: 600px) {
        max-width: 120px;
      }
    }
    .desc-con {
      display: flex;
      flex-direction: column;
      gap: 7px;
      .summary {
        margin-bottom: 5px;
      }
      span {
        margin-right: 12px;
        min-width: 120px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column !important;
    }
  }
`;
