import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Text, Container, CategoryCard, PaginationWrap } from "../../component";
import { StyledPublisher } from "../../theme";
import Image from "../../asset/image";
import { useProduct } from "../../hook";

export const PublisherList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product, publishers_data } = useProduct();
  const [current, setCurrent] = useState(1); //for pagination

  //for pagination
  const onChangePaginate = (pagenumber) => {
    setCurrent(pagenumber);
  };

  useEffect(() => {
    let req_obj = {
      page: current,
      per_page: 10,
      lang: "en",
    };
    dispatch(product.getPublishersAction(req_obj));
  }, []);

  return (
    <StyledPublisher>
      <Container className="container">
        <Text size="xxl" weight="xl">
          Publisher
        </Text>
        <hr />
        <section className="publisher-list-sec">
          {publishers_data &&
            publishers_data?.data &&
            publishers_data?.data?.length > 0 && (
              <div className="row">
                {publishers_data?.data?.map((item, key) => (
                  <div className="col-lg-4 col-md-6 col-12 gy-3" key={key}>
                    <CategoryCard
                      icon={Image.PublisherIcon}
                      title={item.name}
                      onClick={() => {
                        navigate(`/book?name=publisher&field-id=${item.id}&sub-name=${item.name}`);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
        </section>
        <PaginationWrap
          onChange={onChangePaginate}
          current={current}
          total={publishers_data?.total}
          defaultPageSize={10}
        />
      </Container>
    </StyledPublisher>
  );
};
