import styled from "styled-components";
import { color } from "../../attribute";

export const StyledLogin = styled.div`
  width: 100vw;
  height: 110vh;
  display: flex;
  background: ${color.light};
  z-index: 9999;
  overflow-y: scroll;
  @media (max-width: 991px) {
    padding: 30px;
  }
`;

export const StyledLoginForm = styled.div`
  width: 40%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  overflow: hidden;
  img.logo {
    max-width: 160px;
    width: 30vh;
  }
  .form_card {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    h1 {
      margin-bottom: 20px;
    }
    form {
      margin-top: 32px;
    }
    .field-wrapper {
      margin-bottom: 20px;
    }
    button {
      min-width: 150px;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    h1 {
      text-align: center;
    }
    .title-text {
      text-align: center;
    }
    img.logo {
      margin-inline: auto;
      width: 35%;
    }
    form {
      margin-top: 20px !important;
    }
    .form_card {
      width: 60%;
      gap: 20px;
    }
    button {
      margin: auto;
    }
  }

  @media (max-width: 600px) {
    .form_card {
      width: 80%;
    }
  }
`;

export const StyledBannerWrap = styled.div`
  width: 60%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 991px) {
    display: none;
  }
`;
