import Profile from "./Profile.png";
import Logo from "./logo.png";
import download from "./download.png";
import LoginBanner from "./login-banner.png";
import UploadProfile from "./upload-profile.png";
import AnnoBanner from "./annbanner.svg";
import BookIcon from "./book-icon.png";
import BookDefault from "./default_image.jpg";
import ArrowLeft from "./pag-left.png";
import ArrowRight from "./pag-right.png";
import DownArrow from "./down.png";
import NoResult from "./book-search.png";
import PublisherIcon from "./pub-icon.png";
import TagIcon from "./tag.png";
import ZoomIcon from "./zoom.png";
import DownloadIcon from "./down-icon.png";
import MP3 from "./mp3.mp3";
import Video from "./video.mp4";

export default {
  Profile,
  Logo,
  download,
  LoginBanner,
  UploadProfile,
  AnnoBanner,
  BookIcon,
  BookDefault,
  ArrowLeft,
  ArrowRight,
  DownArrow,
  NoResult,
  PublisherIcon,
  TagIcon,
  ZoomIcon,
  DownloadIcon,
  MP3,
  Video
};
