import styled from "styled-components";
import { color, fontFamily, fontWeight } from "../../attribute";

export const StyledAnnouncement = styled.div`
  padding-top: 20px
  background: #f5f5f5;
  border: 1px solid #0000001e;
  border-radius: 16px;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: ${color.gray};
  .header-con {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    h4 {
      margin-right: 20px;
      font-weight: ${(props) =>
        fontWeight[props?.fontWeight] || fontWeight?.lg};
      font-family: ${(props) =>
        fontFamily[props?.fontFamily] || fontFamily?.Popins};
      color: ${(props) => color[props?.Textcolor] || color?.dark2};
      @media (max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    i {
      font-size: 16px !important;
      font-weight: ${(props) =>
        fontWeight[props?.fontWeight] || fontWeight?.sm};
      font-family: ${(props) =>
        fontFamily[props?.fontFamily] || fontFamily?.Popins};
      color: ${(props) => color[props?.Textcolor] || color?.dark};
      text-align: left;
      line-height: 24px;
      @media (min-width: 1500px) {
        font-size: 16px !important;
        line-height: 24px;
      }
      @media (max-width: 767px) {
        font-size: 14px !important;
        line-height: 21px;
      }
    }
  }
  .body-con {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .body {
      font-size: 16px !important;
      font-weight: ${(props) =>
        fontWeight[props?.fontWeight] || fontWeight?.sm};
      font-family: ${(props) =>
        fontFamily[props?.fontFamily] || fontFamily?.Popins};
      color: ${(props) => color[props?.Textcolor] || color?.dark};
      text-align: left;
      line-height: 24px;
      @media (min-width: 1500px) {
        font-size: 16px !important;
        line-height: 24px;
      }
      @media (max-width: 767px) {
        font-size: 14px !important;
        line-height: 21px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 20px 30px;
  }
`;
