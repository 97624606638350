import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import { BsFillPlayFill, BsMusicNote, BsCameraVideo } from "react-icons/bs";
import { useHandleOther, useProduct } from "../../hook";
import {
  Container,
  Text,
  ButtonCom,
  BookCard,
  ModalCom,
} from "../../component";
import { StyledBookDetail } from "../../theme";
import Image from "../../asset/image";
import { handleDateFormat } from "../../component/common/dateFormat";

export const textToUpper = (name) => {
  const inputString = name;
  if (name == "print_copy") {
    let words = inputString.split("_");

    let capitalizedWords = words.map(function (word) {
      let firstChar = word.charAt(0).toUpperCase();
      let restOfWord = word.slice(1).toLowerCase();
      return firstChar + restOfWord;
    });

    let capitalizedSentence = capitalizedWords.join(" ");
    return capitalizedSentence;
  } else {
    const outputString =
      name == "pdf"
        ? inputString.toUpperCase()
        : inputString.charAt(0).toUpperCase() + inputString.slice(1);
    return outputString;
  }
};

const handleName = (name) => {
  if (typeof name === "string") {
    const nameParts = name.split("attachment/");
    if (nameParts.length > 1) {
      const dataName = nameParts[1].split("/")[1];
      return dataName;
    }
  }

  return null;
};

export const BookDetail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const fieldName = params.get("name");
  const fieldId = params.get("field-id");
  const fieldSubName = params.get("sub-name");
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();
  const { product, materialDetail_data, isLoading } = useProduct();
  const vidRef = useRef(null);
  let play_icon = document?.querySelector(".play-icon");
  const [show, setShow] = useState(false);
  const [media, setMedia] = useState("");
  const [data, setData] = useState("");
  const { other, newsCategoryId_data } = useHandleOther();

  useEffect(() => {
    let obj = {
      id: param.id,
      lang: "en",
    };
    dispatch(product.getMaterialDetailAction(obj));
  }, [param.id]);

  useEffect(() => {
    let req_obj = {
      code: "material_request",
    };
    dispatch(other.getNewsCategoryIdAction(req_obj));
  }, []);

  const handlePlayVideo = () => {
    // handleClick(materialDetail_data.id);
    if (vidRef.current.paused) {
      vidRef.current.play();
      play_icon?.setAttribute("style", "display: none");
    } else {
      vidRef.current.pause();
      play_icon?.setAttribute("style", "display: block");
      handleClick(materialDetail_data.id);
    }
  };

  const handleClick = (id) => {
    let obj = {
      id: id,
    };
    product.getRecordAccessLogAction(obj);
  };

  const handleRequest = (id) => {
    let obj = {
      id: id,
    };
    dispatch(product.getAdminRequestAction(obj));
  };

  const handleDownloadClick = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {!isLoading && (
        <StyledBookDetail
          video={materialDetail_data?.type === "video"}
          mp3={materialDetail_data?.type === "mp3"}
        >
          {materialDetail_data && (
            <Container className="container">
              <Text as="h1" className="title" size="xl" weight="lg">
                {materialDetail_data?.title}
              </Text>
              {materialDetail_data?.tag?.length > 0 && (
                <div className="tag-con d-flex">
                  {materialDetail_data?.tag?.map((item, key) => (
                    <Text color="primary" key={key}>
                      <div
                        onClick={() => navigate("/search/" + item)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={Image.TagIcon} />
                        {item}
                      </div>
                    </Text>
                  ))}
                </div>
              )}
              <hr />
              <section className="book-detail-sec">
                <div className="image-wrap">
                  <div className="wrap">
                    <img
                      src={materialDetail_data?.image || Image.BookDefault}
                      alt="book-detail-image"
                    />
                    {materialDetail_data?.image && (
                      <img
                        src={Image.ZoomIcon}
                        className="zoom"
                        alt="zoom-icon"
                        onClick={() => {
                          setShow(true);
                          setData(materialDetail_data?.image);
                          setMedia("photo");
                        }}
                      />
                    )}
                  </div>
                  {/* {materialDetail_data?.type == "mp3" &&
                    materialDetail_data.attachment_file?.length > 0 &&
                    materialDetail_data.attachment_file?.length <= 1 && (
                      <div className="audio-con">
                        <AudioPlayer
                          src={materialDetail_data?.attachment_file[0]}
                          id={materialDetail_data.id}
                          handleClick={handleClick}
                        />
                      </div>
                    )} */}
                </div>
                <div className="content-wrap">
                  {materialDetail_data?.category && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Subject Domain
                      </Text>{" "}
                      :<Text>{materialDetail_data?.category?.name}</Text>
                    </div>
                  )}
                  {materialDetail_data?.accession_number && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Accession Number
                      </Text>{" "}
                      :<Text>{materialDetail_data?.accession_number}</Text>
                    </div>
                  )}
                  {materialDetail_data?.classification_number && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Classification Number
                      </Text>{" "}
                      :<Text>{materialDetail_data?.classification_number}</Text>
                    </div>
                  )}
                  {materialDetail_data?.language && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Language
                      </Text>{" "}
                      :
                      <Text>
                        {materialDetail_data?.language == "my"
                          ? "Myanmar"
                          : "English"}
                      </Text>
                    </div>
                  )}
                  {materialDetail_data?.author && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Author
                      </Text>{" "}
                      :<Text>{materialDetail_data?.author?.name}</Text>
                    </div>
                  )}
                  {materialDetail_data?.ISBN && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        ISBN
                      </Text>{" "}
                      :<Text>{materialDetail_data?.ISBN}</Text>
                    </div>
                  )}
                  {materialDetail_data?.edition && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Edition
                      </Text>{" "}
                      :<Text>{materialDetail_data?.edition}</Text>
                    </div>
                  )}
                  {materialDetail_data?.total_page > 0 && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Total Page
                      </Text>{" "}
                      :<Text>{materialDetail_data?.total_page}</Text>
                    </div>
                  )}
                  {materialDetail_data?.book_size && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Book Size
                      </Text>{" "}
                      :<Text>{materialDetail_data?.book_size}</Text>
                    </div>
                  )}
                  {materialDetail_data?.publication_date && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        Publication Date
                      </Text>{" "}
                      :
                      <Text>
                        {handleDateFormat(
                          materialDetail_data?.publication_date
                        )}
                      </Text>
                    </div>
                  )}
                  {materialDetail_data?.type && (
                    <div className="wrap d-flex">
                      <Text size="sm" color="gray4" as="span">
                        File Format
                      </Text>{" "}
                      :<Text>{textToUpper(materialDetail_data?.type)}</Text>
                    </div>
                  )}
                  {materialDetail_data?.summary && (
                    <Text size="md" weight="md" className="summary">
                      {materialDetail_data?.summary || "aa"}
                    </Text>
                  )}
                  {materialDetail_data.attachment_file &&
                    materialDetail_data.attachment_file?.length > 0 &&
                    materialDetail_data.attachment_file?.length <= 1 && (
                      <ButtonCom
                        className={`download-btn ${
                          (materialDetail_data.type == "video" ||
                            materialDetail_data.type == "mp3") &&
                          "d-none"
                        }`}
                        text={`DOWNLOAD ${materialDetail_data?.type}`}
                        color="light"
                        bgcolor="primary"
                        btnBorderRadius="xxxl"
                        SVGL={<img src={Image.DownloadIcon} />}
                        onClick={() => {
                          // window.open(
                          //   `${materialDetail_data.attachment_file[0]}`,
                          //   "download"
                          // );
                          handleDownloadClick(
                            materialDetail_data?.attachment_file[0],
                            materialDetail_data?.type
                          );
                          handleClick(materialDetail_data.id);
                        }}
                      />
                    )}
                  {materialDetail_data.attachment_file &&
                    materialDetail_data.attachment_file?.length > 1 &&
                    (materialDetail_data.type == "slideshow" ||
                      materialDetail_data.type == "pdf") &&
                    materialDetail_data.attachment_file.map((item, idx) => (
                      <button
                        key={idx}
                        className="btn d-flex justify-content-between align-items-center my-2 px-2 w-50 multi-down-btn"
                        style={{
                          minHeight: "50px",
                          border: "0.3px solid gray",
                        }}
                        onClick={() => {
                          window.open(`${item}`, "download");
                          handleClick(materialDetail_data.id);
                        }}
                      >
                        <div className=" d-flex align-items-center gap-2">
                          <AiOutlineFileText
                            size={20}
                            className=" text-black-50"
                          />
                          <span className=" small text-start">
                            {handleName(item)}
                          </span>
                        </div>
                        <div className=" bg-primary py-1 px-2 rounded-circle">
                          <img
                            src={Image.DownloadIcon}
                            alt=""
                            style={{ width: "15px" }}
                          />
                        </div>
                      </button>
                    ))}
                  {materialDetail_data.attachment_file &&
                    materialDetail_data.attachment_file?.length >= 1 &&
                    (materialDetail_data.type == "video" ||
                      materialDetail_data.type == "mp3") &&
                    materialDetail_data.attachment_file.map((item, idx) => (
                      <button
                        key={idx}
                        className="btn d-flex justify-content-between align-items-center my-2 px-2 w-50 multi-down-btn"
                        style={{
                          minHeight: "50px",
                          border: "0.3px solid gray",
                        }}
                        onClick={() => {
                          setMedia(materialDetail_data?.type);
                          setData(item);
                          setShow(true);
                        }}
                      >
                        <div className=" d-flex align-items-center gap-2 me-2">
                          {materialDetail_data.type == "mp3" ? (
                            <BsMusicNote size={20} className=" text-black-50" />
                          ) : (
                            <BsCameraVideo
                              size={20}
                              className=" text-black-50"
                            />
                          )}
                          <span className=" small text-start">
                            {handleName(item)}
                          </span>
                        </div>
                        <div className="">
                          <div
                            className=" bg-primary rounded-circle d-flex justify-content-center align-items-center"
                            style={{ width: "25px", height: "25px" }}
                          >
                            <BsFillPlayFill size={20} className=" text-white" />
                          </div>
                        </div>
                      </button>
                    ))}

                  {/* {materialDetail_data?.type == "video" &&
                    materialDetail_data.attachment_file?.length > 0 &&
                    materialDetail_data.attachment_file?.length <= 1 && (
                      <div
                        className="video-con"
                        onClick={() => {
                          handlePlayVideo();
                        }}
                      >
                        <video ref={vidRef}>
                          <source
                            src={materialDetail_data?.attachment_file[0]}
                            type="video/mp4"
                            alt="video"
                          />
                        </video>
                        <MdPlayCircleOutline
                          className="play-icon"
                          color="white"
                        />
                      </div>
                    )} */}

                  {/* request-admin */}
                  {materialDetail_data?.need_admin_permission == true &&
                    materialDetail_data?.permission_status ===
                      "need_permission" && (
                      <div className="request-admin">
                        <ButtonCom
                          className="download-btn"
                          text="Request admin to view"
                          color="light"
                          bgcolor="primary"
                          btnBorderRadius="xxxl"
                          onClick={() => {
                            handleRequest(materialDetail_data.id);
                            window.location.reload();
                          }}
                        />
                        <Text size="sm" color="danger">
                          *{newsCategoryId_data?.material_request.text_one}.
                        </Text>
                      </div>
                    )}

                  {/* request-admin */}
                  {/* {materialDetail_data?.need_admin_permission == true &&
                    materialDetail_data?.permission_status === "allowed" && (
                      <div className="request-admin">
                        <ButtonCom
                          className="download-btn"
                          text={`DOWNLOAD`}
                          color="light"
                          bgcolor="primary"
                          btnBorderRadius="xxxl"
                          onClick={() => {
                            window.open(
                              `${materialDetail_data.attachment_file[0]}`,
                              "download"
                            );
                            handleClick(materialDetail_data.id);
                          }}
                        />
                      </div>
                    )} */}

                  {/* request-admin */}
                  {materialDetail_data?.need_admin_permission == true &&
                    materialDetail_data?.permission_status === "requested" && (
                      <div className="request-admin">
                        <Text size="sm" color="danger">
                          *{newsCategoryId_data?.material_request.text_two}
                        </Text>
                      </div>
                    )}

                  {/* request-admin */}
                  {materialDetail_data?.need_admin_permission == true &&
                    materialDetail_data?.permission_status === "denied" && (
                      <div className="request-admin">
                        <Text size="sm" color="danger">
                          * {newsCategoryId_data?.admin_response.text_one}
                        </Text>
                      </div>
                    )}

                  {/* hard-copy */}
                  {materialDetail_data.type == "print_copy" && (
                    <Text
                      className="hard-copy-text"
                      color="warn"
                      size="lg"
                      weight="xl"
                    >
                      {newsCategoryId_data?.admin_response.text_two}. "
                      Accession number : {materialDetail_data?.accession_number}{" "}
                      "
                    </Text>
                  )}

                  <ButtonCom
                    btnBorderRadius="xxxl"
                    text="Back"
                    color="text"
                    bgcolor="gray"
                    SVGL={<BsArrowLeftShort size={25} />}
                    onClick={() =>
                      navigate(
                        `/book?name=${fieldName}&field-id=${fieldId}&sub-name=${fieldSubName}`
                      )
                    }
                  />
                </div>
              </section>
              {materialDetail_data?.related_material?.length > 0 && (
                <section className="related-sec">
                  <Text as="h1" className="title" size="xxl" weight="xl">
                    Related Books
                  </Text>
                  <div className="row">
                    {materialDetail_data?.related_material?.map((item, key) => (
                      <div className="col-lg-6 col-12" key={key}>
                        <BookCard
                          image={item.image}
                          title={item.title}
                          // summary={item.summary}
                          subject={materialDetail_data.category.name}
                          tag={item?.tag}
                          // language={"Myanmar"}
                          author={item?.author?.name}
                          publisher={item?.publisher?.name}
                          type={item.type}
                          onClick={() => navigate(`/book/${item.id}`)}
                        />
                      </div>
                    ))}
                  </div>
                </section>
              )}
              <Modal
                show={show}
                setShow={setShow}
                type={media}
                data={data}
                handlePlayVideo={handlePlayVideo}
                vidRef={vidRef}
                handleClick={handleClick}
                id={materialDetail_data.id}
              />
            </Container>
          )}
        </StyledBookDetail>
      )}
    </>
  );
};

const Modal = ({ show, setShow, type, data, handleClick, id }) => {
  const mediaRef = useRef(null);
  const startRequest = (id) => {
    setTimeout(() => {
      handleClick(id);
    }, 2000);
  };

  const restartMedia = () => {
    if (mediaRef?.current) {
      mediaRef.current.currentTime = 0;
      mediaRef.current.pause();
    }
  };

  if (type == "photo") {
    return (
      <ModalCom isModal={show} handleIsModal={setShow} isCloseBtn={true}>
        <img src={data} alt="detail-image" className=" mx-auto" />
      </ModalCom>
    );
  }
  if (type == "mp3") {
    return (
      <ModalCom
        isModal={show}
        handleIsModal={setShow}
        isCloseBtn={true}
        restartMedia={restartMedia}
      >
        <div className="audio-con">
          <div className="p-3 d-flex justify-content-center">
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                backgroundColor: "#999",
              }}
            >
              <BsMusicNote size={50} />
            </div>
          </div>
          {/* <AudioPlayer src={data}/> */}
          <audio
            ref={mediaRef}
            controls
            style={{ width: "100%" }}
            className="px-2"
            onPlay={() => startRequest(id)}
          >
            <source src={data} />
          </audio>
        </div>
      </ModalCom>
    );
  }
  if (type == "video") {
    return (
      <ModalCom
        isModal={show}
        handleIsModal={setShow}
        isCloseBtn={true}
        restartMedia={restartMedia}
      >
        {data && (
          <video
            ref={mediaRef}
            controls
            width={"100%"}
            onPlay={() => startRequest(id)}
          >
            <source src={data} type="video/mp4" alt="video" />
          </video>
        )}
      </ModalCom>
    );
  }
};
