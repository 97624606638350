import React from 'react'
import Pagination from 'rc-pagination'

import { StyledPagination } from '../../../theme'

export const PaginationWrap = ({ total, onChange, current, defaultPageSize, ...props }) => {
  return (
    <StyledPagination className={`${total > defaultPageSize ? '' : 'hide'}`}>
      <Pagination onChange={onChange} current={current} total={total} showLessItems={true} showTitle={false} {...props} pageSize={defaultPageSize} />
    </StyledPagination>
  )
}
