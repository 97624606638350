import React, { useEffect } from "react";
import { Container, Text } from "../../component";
// import Icon from "asset/icon/pladco";

export const NotFoundPage = () => {
  return (
    <Container
      className="container not-found-page d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "50vh", paddingBlock: "40px" }}
    >
      {/* <img src={Icon.notfound} alt="" className="img-fluid" /> */}
      <Text weight="lg" size="xxl" color='primary'>
      Oops! Page can’t be found.
      </Text>
    </Container>
  );
};
