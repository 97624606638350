import React from "react";

import { StyledFieldWrapper, StyledInputContainer, StyledLabel } from "../../../theme";
import { Text } from "../typo";

export const FieldWrapperCom = ({
  children,
  meta,
  label,
  id,
  required,
  ...props
}) => {
  return (
    <StyledFieldWrapper {...props} className="field-wrapper">
      {label && (
        <StyledLabel {...props} className='label' >
          <label className="label_txt" htmlFor={id}>{label}</label>
          {required && <Text color="danger">*</Text>}
        </StyledLabel>
      )}
      <StyledInputContainer {...props}>{children}</StyledInputContainer>
      {meta?.touched && meta?.error && (
        <Text className="err_msg" color="danger" size="xxs">
          {meta?.error}
        </Text>
      )}
    </StyledFieldWrapper>
  );
};
