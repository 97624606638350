import styled from "styled-components";
import { color } from "../../attribute";

export const StyledChangePassword = styled.div`
  padding-block: 50px;
  .form-wrap {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-block: 22px;
    border: 1px solid ${color.border};
    padding: 30px;
    border-radius: 16px;
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .form_foot_sec {
        justify-content: center;
      }
      button {
        padding-inline: 40px;
        @media (max-width: 375px) {
          padding-inline: 20px;
        }
      }
    }
    @media (max-width: 768px) {
      padding-inline: 20px;
    }
  }
`;
