import React, { useEffect } from "react";

import { Text, ButtonCom, InputCom } from "../../component";
import { FormContainerCom } from "../../component/common";
import { StyledLogin, StyledLoginForm, StyledBannerWrap } from "../../theme";
import Image from "../../asset/image";
import { useHandleAuth, useHandleOther } from "../../hook";
import { loginAction } from "../../services/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { backgroundImages } from "polished";
import { Navigate, useNavigate } from "react-router-dom";

export const Login = () => {
  const { handleSignIn } = useHandleAuth();
  const { other, banner_data } = useHandleOther();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let image = banner_data ? banner_data[0]?.desktop_image : Image.LoginBanner;
  image = image.split("?")[0];

  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.login_data && auth?.login_data?.user_id) {
      navigate("/");
    }
  }, [auth]);

  let initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = (yup) =>
    yup.object({
      email: yup
        .string()
        .email("Email is Required")
        .required("Email is Required"),
      password: yup.string().required("Password is Required"),
    });

  const onDataSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      let post_data = {
        login: values?.email,
        password: values?.password,
      };
      handleSignIn(post_data);
      actions.setSubmitting(false);
      actions.resetForm();
    }, 1000);
  };

  useEffect(() => {
    dispatch(other.getBannerAction());
  }, []);

  return (
    <StyledLogin>
      <StyledLoginForm>
        <div className="form_card">
          <img src={Image.Logo} className="logo" />
          <div className="wrapper">
            <Text size="hl" weight="lg" as="h1">
              Welcome
            </Text>
            <Text size="lg" className="title-text">
              Please Login to your account
            </Text>
            <FormContainerCom
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onDataSubmit(values, actions)}
            >
              {(formikProps) => (
                <>
                  <InputCom label="Email" name="email" type="email" />
                  <InputCom label="Password" name="password" type="password" />
                  <div className="form_foot_sec">
                    <ButtonCom
                      type="submit"
                      color="light"
                      bgcolor="primary"
                      btnBorderRadius="xxxl"
                      text={
                        formikProps.isSubmitting ? "Logining in...." : "LOGIN"
                      }
                    />
                  </div>
                </>
              )}
            </FormContainerCom>
          </div>
        </div>
      </StyledLoginForm>
      <StyledBannerWrap
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></StyledBannerWrap>
    </StyledLogin>
  );
};
