import styled from "styled-components";
import { color } from "../../attribute";

export const StyledBookDetail = styled.div`
  hr {
    margin-block: 25px;
  }
  h1.title {
    margin-bottom: 10px;
  }
  .tag-con {
    gap: 12px;
    flex-wrap: wrap;
    img {
      height: auto;
      margin-right: 8px;
    }
  }
  .modaldialog {
    width: ${(props) => (props.video ? "60vw" : "40vw")};
    @media (max-width: 998px) {
      min-width: ${(props) => (props.video || props.mp3) ? "80vw" : "60vw"};
    }
    @media (max-width: 600px) {
      min-width: ${(props) => (props.video || props.mp3) ? "90vw" : "80vw"};
    }
    .modal-content {
      padding: 0;
      img {
        height: 400px;
      }
      @media (max-width: 768px) {
        img {
          height: 400px;
        }
      }
      @media (max-width: 600px) {
        img {
          height: 300px;
        }
      }
    }
  }
  .book-detail-sec {
    display: flex;
    .image-wrap {
      width: 25%;
      height: max-content;
      .wrap {
        position: relative;
      }
      img {
        width: 100%;
      }
      img.zoom {
        width: 35px;
        position: absolute;
        bottom: 7px;
        right: 7px;
        border-radius: 30px;
        background: ${color.gray3};
        padding: 8px 5px 4px 8px;
        cursor: pointer;
        &:hover {
          background: ${color.gray4};
        }
      }
    }
    .content-wrap {
      width: 75%;
      padding-left: 30px;
      .summary {
        margin-bottom: 20px;
      }
      .wrap {
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        span {
          min-width: 150px;
        }
      }
      button {
        margin-block: 30px;
        padding-inline: 20px;
      }
      .video-con {
        position: relative;
        margin-top: 30px;
        video {
          width: 100%;
        }
        svg {
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          width: 60px;
          height: 60px;
        }
      }
      .hard-copy-text {
        background: ${color.warn_light};
        width: max-content;
        padding: 16px;
        border-radius: 5px;
      }
      .request-admin {
        margin-bottom: 20px;
        button {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
      .multi-down-btn {
      }
      @media (max-width: 992px) {
        .multi-down-btn {
          width: 80% !important;
        }
      }
      @media (max-width: 600px) {
        .multi-down-btn {
          width: 100% !important;
        }
        .hard-copy-text{
          width: auto;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
      .image-wrap {
        width: 50%;
      }
      .content-wrap {
        width: 100%;
        padding-left: 0;
      }
    }
    @media (max-width: 600px) {
      .image-wrap {
        width: 100%;
      }
    }
  }
  .related-sec {
    margin-top: 50px;
    h1.title {
      margin-bottom: 30px;
    }
    .row {
      row-gap: 30px;
    }
  }
`;
