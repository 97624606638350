import { Home } from "../screen/home";
import { Announcement, NotFoundPage } from "../screen/other"
import { Login, ChangePassword, Profile } from "../screen/auth";
import { AuthorList, BookList, PublisherList, SearchResult, SubCategory, BookDetail } from "../screen/product";

export const RouteConfig = [
  {
    path: '/',
    element: <Home />,
    protect: true
  },
  {
    path: '/announcement',
    element: <Announcement />,
    protect: true
  },
  {
    path: '/user/signin',
    element: <Login />
  },
  {
    path: '/user/change_password',
    element: <ChangePassword />,
    protect: true
  },
  {
    path: "/user/profile",
    protect: true,
    element: <Profile />,
  },
  {
    path: '/author',
    element: <AuthorList/>,
    protect: true
  },
  {
    path: '/publisher',
    element: <PublisherList/>,
    protect: true
  },
  {
    path: '/book',
    element: <BookList/>,
    protect: true
  },
  {
    path: '/book/:id',
    element: <BookDetail/>,
    protect: true
  },
  {
    path: '/search/:keyword',
    element: <SearchResult/>,
    protect: true
  },
  {
    path: '/subcategory',
    element: <SubCategory/>,
    protect: true
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]