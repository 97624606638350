import { useTheme } from '../component/common'
import { useDispatch, useSelector } from 'react-redux'
import theme from '../services/actions/theme'


export const useToast = (props) => {
  const dispatch = useDispatch()
  const theme_state = useSelector(state => state.theme)

  const showToast = async (data) => {
    let final_data = {
      ...data,
      toastModalVisible: true,
    }
    await dispatch(theme.setToast(final_data))
  }

  const hideToast = async () => {
    await dispatch(theme.setToast({ toastModalVisible: false }))
  }

  return {
    ...theme_state,
    showToast,
    hideToast,
  }
}