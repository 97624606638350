import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Image from "../../asset/image";

import {
  Container,
  Text,
  SearchFilterCom,
  CategoryCard,
} from "../../component";
import { useProduct, useHandleOther} from "../../hook";
import { StyledHome } from "../../theme/custom/home";
import Slider from "./slider";

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { product, category_data } = useProduct();
  const {other,announcement_data } = useHandleOther();

  useEffect(() => {
    dispatch(product.getCategoryAction({ lang: "en" }));
    dispatch(other.getAnnouncementAction())
  }, []);

  const handleNavigate = (route, state) => {
    navigate(`${route}`, {
      replace: true,
      state: state,
    });
    window.location.reload();
  };

  return (
    <StyledHome>
      <Container className="container">
        <Slider sliderData={announcement_data}/>
        <section className="search-sec">
          <SearchFilterCom data={'book name'}/>
        </section>
        <section>
          {category_data?.status!=="fail" && (
            <>
              <hr />
              <div className="row category-wrap">
                <Text size="xxxl" weight="xl" textAlign="center">
                  Subject Domain
                </Text>
                {category_data?.map((item, key) => (
                  <div className="col-lg-4 col-md-6 col-12 gy-3" key={key}>
                    <CategoryCard
                      icon={Image.BookIcon}
                      title={item.name}
                      arrow={true}
                      onClick={() =>
                        item?.sub_category?.length > 0
                          ? handleNavigate(`subcategory?name=sub-category&field-id=${item.id}&sub-name=${item.name}`)
                          : handleNavigate(`/book?name=category&field-id=${item.id}&sub-name=${item.name}`)
                      }
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </section>
      </Container>
    </StyledHome>
  );
};
