import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";

import {
  Text,
  Container,
  InputCom,
  PaginationWrap,
  BookCard,
} from "../../component";
import { useProduct } from "../../hook";
import { StyledBooklist } from "../../theme";
import Image from "../../asset/image";

export const BookList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const fieldName = params.get("name");
  const fieldId = params.get("field-id");
  const fieldSubName = params.get("sub-name");
  const {
    product,
    authors_data,
    materials_data,
    authorDetail_data,
    publisherDetail_data,
    isLoading,
  } = useProduct();
  const MaterialsData = materials_data;

  const [bookname, setBookname] = useState(""); //for search
  const [authorid, setAuthorId] = useState(""); //for filter
  const [materialtype, setMaterialType] = useState("");
  const [lang, setLang] = useState("");
  const [sortby, setSortby] = useState("");
  const [current, setCurrent] = useState(1); //for pagination
  const [authorinfo, setAuthorInfo] = useState("");

  let publisher_id = "";
  let author_id = "";
  let category_id = "";
  if (fieldName && fieldId) {
    switch (fieldName) {
      case "publisher":
        publisher_id = fieldId;
        break;

      case "author":
        author_id = fieldId;
        break;

      case "category":
        category_id = fieldId;
        break;

      default:
        break;
    }
  }

  //get author list
  useEffect(() => {
    let obj = {
      lang: "en",
      page_no: 1,
      per_page: authors_data?.total,
      category_id: category_id,
    };
    dispatch(product.getAuthorsAction(obj));
  }, [category_id]);

  useEffect(() => {
    dispatch(product.removeMaterialAction());
  }, []);

  // detail
  useEffect(() => {
    let obj = {
      id: fieldId,
      lang: "en",
    };
    if (fieldName == "author") {
      dispatch(product.getAuthorDetailAction(obj));
    } else if (fieldName == "publisher") {
      dispatch(product.getPublisherDetailAction(obj));
    }
  }, [fieldId, fieldName]);

  //filter query
  useEffect(() => {
    let req_obj = {
      category_id: category_id,
      page_no: current,
      per_page: 10,
      lang: lang || "en",
    };
    if (fieldName == "author") {
      req_obj.author_id = author_id;
    }
    if (authorid !== "") {
      req_obj.author_id = authorid;
    }
    if (materialtype !== "") {
      req_obj.material_type = materialtype;
    }
    if (bookname !== "") {
      req_obj.search = bookname;
    }
    if (sortby !== "") {
      req_obj.sort_by = sortby;
    }
    if (publisher_id !== "") {
      req_obj.publisher_id = publisher_id;
    }
    if (fieldName == "publisher") {
      req_obj.publisher_id = publisher_id;
    }
    dispatch(product.getMaterialAction(req_obj));
  }, [
    fieldId,
    fieldName,
    // bookname,
    current,
    authorid,
    materialtype,
    sortby,
    lang,
  ]);

  //for search
  const handleSearch = () => {
    let search_obj = {
      search: bookname,
    };
    bookname && dispatch(product.getMaterialAction(search_obj));
    bookname !== "" && navigate(`/search/${bookname}`); //if user hit enter, go to search result page
    setBookname("");
  };

  //for pagination
  const onChangePaginate = (pagenumber) => {
    setCurrent(pagenumber);
  };

  useEffect(() => {
    setAuthorInfo(authors_data?.data?.filter((a) => a?.id == authorid)[0]);
  }, [authorid]);

  return (
    <>
      {!isLoading && (
        <StyledBooklist>
          <Container className="container">
            <Text as="p" className="title" size="xl" weight="lg">
              {fieldName &&
                (fieldName === "category" ? (
                  <> {fieldSubName || ""}</>
                ) : (
                  <>
                    {fieldSubName} (
                    {fieldName === "author" ? "Author Name" : "Publisher"}){" "}
                    {fieldName == "author" ? (
                      <Text
                        as="span"
                        size="xl"
                        weight="lg"
                        color="primary_light"
                        fontStyle="italic"
                      >
                        {authorDetail_data?.abbreviation}
                      </Text>
                    ) : (
                      <Text
                        as="span"
                        size="xl"
                        weight="lg"
                        color="primary_light"
                        fontStyle="italic"
                      >
                        {publisherDetail_data?.abbreviation}
                      </Text>
                    )}
                  </>
                ))}
              {fieldName==="author" && (
                  <Text
                    size="sm"
                    color="gray4"
                    className="d-flex align-items-center"
                  >
                    {authorDetail_data?.biography}
                  </Text>
                )}
            </Text>
            {publisherDetail_data && fieldName==="publisher" && (
              <div className="summary-con mt-2">
                {publisherDetail_data?.address && (
                  <Text
                    size="sm"
                    color="gray4"
                    className="d-flex align-items-center"
                  >
                    <MdLocationOn color="#00000099" />
                    {publisherDetail_data?.address}
                  </Text>
                )}
                {publisherDetail_data.summary && (
                  <Text size="sm" color="gray4">
                    {publisherDetail_data?.summary}
                  </Text>
                )}
              </div>
            )}
            {
              // materials_data && materials_data?.data && materials_data?.data?.length>0 &&
              <div className="search-wrap">
                <Text>Found {materials_data?.total} books</Text>
                <InputCom
                  isValidate={false}
                  placeholder="Search book name"
                  type="text"
                  value={bookname}
                  onChange={(e) => {
                    setBookname(e.target.value);
                  }}
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                />
              </div>
            }
            <hr />
            {/* search menu bar  */}
            {location?.state?.type !== "author" &&
              location?.state?.type !== "publisher" && (
                <section className="filter-sec d-flex justify-content-between">
                  <div className="filter-wrap d-flex align-items-center gap-3">
                    <Text weight="xl">Filter :</Text>
                    <div className="d-flex flex-wrap gap-2 gap-md-3">
                      {authors_data &&
                        authors_data?.data &&
                        authors_data?.data?.length > 0 && (
                          <InputCom
                            isValidate={false}
                            input_as="select"
                            onChange={(e) => setAuthorId(e.target.value)}
                            className="author-input"
                            defaultValue={authorid}
                          >
                            <option value="">Author</option>
                            {authors_data?.data?.map((item, key) => (
                              <option value={item.id} key={key}>
                                {item?.name}
                              </option>
                            ))}
                          </InputCom>
                        )}
                      <InputCom
                        isValidate={false}
                        input_as="select"
                        onChange={(e) => setMaterialType(e.target.value)}
                        className="format-input"
                        defaultValue={materialtype}
                      >
                        <option value="">File Format</option>
                        <option value="pdf">PDF</option>
                        <option value="slideshow">Slideshow</option>
                        <option value="mp3">MP3</option>
                        <option value="video">Video</option>
                        <option value="print_copy">Print Copy</option>
                      </InputCom>
                      <InputCom
                        isValidate={false}
                        input_as="select"
                        onChange={(e) => setLang(e.target.value)}
                        className="lang-input"
                        defaultValue={lang}
                      >
                        <option value="">Language</option>
                        <option value="en">English</option>
                        <option value="my">Myanmar</option>
                      </InputCom>
                    </div>
                  </div>
                  <div className="sort-by d-flex align-items-center gap-3">
                    <Text weight="xl">Sort By :</Text>
                    <InputCom
                      isValidate={false}
                      input_as="select"
                      onChange={(e) => setSortby(e.target.value)}
                      className="sortby-input"
                      defaultValue={sortby}
                    >
                      <option value="">All</option>
                      <option value="NAMEASC">Name A to Z</option>
                      <option value="NAMEDESC">Name Z to A</option>
                    </InputCom>
                  </div>
                </section>
              )}
            {/* book list show ui  */}
            {materials_data &&
            materials_data?.data &&
            materials_data?.data?.length > 0 ? (
              <>
                <section className="booklist-sec">
                  {MaterialsData == materials_data &&
                    !isLoading &&
                    materials_data?.data && (
                      <div className="row">
                        {materials_data == materials_data &&
                          materials_data?.data?.map((item, key) => (
                            <div className="col-lg-6 col-12" key={key}>
                              <BookCard
                                image={item.image}
                                title={item.title}
                                // summary={item.summary}
                                // subject={
                                //   fieldId &&
                                //   fieldName === "category"
                                //     ? `${main_category?.name}${
                                //         sub_category
                                //           ? ` / ${sub_category?.name}`
                                //           : ""
                                //       }`
                                //     : ""
                                // }
                                tag={item?.tag}
                                // language={"Myanmar"}
                                author={item?.author?.name}
                                publisher={item?.publisher?.name}
                                type={item.type}
                                onClick={() =>
                                  navigate(
                                    `/book/${item.id}?name=${fieldName}&field-id=${fieldId}&sub-name=${fieldSubName}`
                                  )
                                }
                              />
                            </div>
                          ))}
                      </div>
                    )}
                </section>
                <PaginationWrap
                  onChange={onChangePaginate}
                  current={current}
                  total={materials_data?.total}
                  defaultPageSize={10}
                />
              </>
            ) : (
              <div className="no-result">
                <img src={Image.NoResult} alt="no-result-icon" />
                <Text size="xxl" color="gray2">
                  Sorry, No Book Found!
                </Text>
              </div>
            )}
          </Container>
        </StyledBooklist>
      )}
    </>
  );
};
