import React from "react";
import { useLocation } from "react-router-dom";

import { StyledMain } from "../../../theme/common/layout";

export const Main = props => {
  const location = useLocation()
  return (
    <StyledMain style={{paddingBlock: location?.pathname==='/user/signin'? '0':''}}>
      {props?.children ? props?.children : <h1>Add UI Component Here</h1>}
    </StyledMain>
  )
}