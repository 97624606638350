import styled from "styled-components";

export const StyledSubcategory = styled.div`
  .title {
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  min-height: 70vh;
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .field-wrapper {
      width: 30%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
      .field-wrapper {
        width: 50%;
      }
    }
    @media (max-width: 425px) {
      .field-wrapper {
        width: 100%;
      }
    }
  }
  .subcategory-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
