import React from 'react'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

export const FormContainerCom = ({ children, validationSchema, ...props }) => {
  return (
    <Formik enableReinitialize validationSchema={() => validationSchema(yup)} {...props}>
      {(formikProps) => <Form style={{ width: '100%' }}>{children(formikProps)}</Form>}
    </Formik>
  )
}