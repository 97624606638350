import styled from "styled-components";
import { color } from "../../attribute";

export const StyledAuthor = styled.div`
  hr {
    margin-block: 25px;
  }
  .language-tab {
    width: 50%;
    display: flex;
    align-items: center;
    border: 1px solid ${color.border};
    border-radius: 8px;
    p {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      transition: all 0.1s ease-in;
      cursor: pointer;
      &.active {
        background: ${color.primary};
        color: ${color.light};
        border-radius: 8px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .characters {
    margin-top: 50px;
    margin-bottom: 30px;
    gap: 12px;
    a {
      background: ${color.gray};
      border-radius: 3px;
      padding: 1px 7px;
      cursor: text;
      &:hover {
        color: ${color.text};
      }
      &.disabled {
        opacity: 0.4;
      }
      &:not(.disabled):hover {
        background: ${color.primary_light};
        color: ${color.light};
        cursor: pointer;
      }
    }
  }
  .author-list-sec {
    .wrap {
      border-top: 1px solid ${color.border};
      padding: 30px;
      gap: 5%;
      align-items: flex-start;
      .names {
        width: 100%;
        row-gap: 16px;
        a {
          width: 30%;
          cursor: pointer;
          &:hover {
            color: ${color.primary_light};
          }
          @media (max-width: 991px) {
            width: 50%;
          }
          @media (max-width: 425px) {
            width: 100%;
          }
        }
        @media (max-width: 425px) {
          row-gap: 10px;
        }
      }
      @media (max-width: 768px) {
        padding-inline: 15px;
      }
    }
  }
`;
