import styled from "styled-components";

export const StyledMain = styled.main`
  min-height: 50vh;
  overflow-x: hidden;
  padding-block: 30px;
`;

export const StyledSection = styled.div`
  width: 100vw;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;
export const StyledContainer = styled.div`
  background: ${(props) =>
    props?.theme?.color?.container?.[props?.bgColor] ||
    props?.bgColor ||
    props?.theme?.color?.container?.light};
  // width:70vw;
  // position:absolute;
  // display:flex;
  // justify-content:center;

  // @media(max-width:991px){
  //   width:90vw;
  // }
`;
