import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  SearchFilterCom,
  BookCard,
  Text,
  PaginationWrap,
  Container,
} from "../../component";
import { useProduct } from "../../hook";
import { StyledSearchResult } from "../../theme";
import Image from "../../asset/image";

export const SearchResult = () => {
  const { product, materials_data } = useProduct();
  const location = useLocation();
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1); //for pagination

  //for pagination
  const onChangePaginate = (pagenumber) => {
    setCurrent(pagenumber);
  };

  useEffect(() => {
    let req_obj = {
      page: current,
      per_page: 10,
    };
    if (location.state !== null) {
      switch (location.state.filter) {
        case "author":
          req_obj.author = param.keyword;
          break;
        case "publisher":
          req_obj.publisher = param.keyword;
          break;
        case "accession number":
          req_obj.accession_number = param.keyword;
          break;
        case "isbn":
          req_obj.ISBN = param.keyword;
          break;
        case "publication year":
          req_obj.publication_year = param.keyword;
          break;
        default:
          req_obj.search = param.keyword;
          break;
      }
    } else {
      req_obj.search = param.keyword;
    }

    dispatch(product.getMaterialAction(req_obj));
  }, [param.keyword, current]);

  return (
    <StyledSearchResult>
      <Container className="container">
        <section className="search-sec">
          <div className="row">
            <section className="search-sec">
              <SearchFilterCom />
            </section>
          </div>
        </section>
        <section className="search-result-sec">
          <Text as="h1" size="xxl" weight="xxl" className="title">
            Search result for "{" "}
            {location.state ? `${location.state.filter} : ` : "book name : "}{" "}
            {param.keyword} "
          </Text>
          <Text>Found {materials_data?.total} books</Text>
          <hr />
          {materials_data &&
          materials_data?.data &&
          materials_data?.data?.length > 0 ? (
            <>
              <section className="booklist-sec">
                <div className="row">
                  {materials_data?.data?.map((item, key) => (
                    <div className="col-lg-6 col-12" key={key}>
                      <BookCard
                        image={item.image}
                        title={item.title}
                        // summary={item.summary}
                        tag={item?.tag}
                        language={"Myanmar"}
                        author={item?.author?.name}
                        publisher={item?.publisher?.name}
                        type={item.type}
                        onClick={() => navigate(`/book/${item.id}`)}
                      />
                    </div>
                  ))}
                </div>
              </section>
              <PaginationWrap
                onChange={onChangePaginate}
                current={current}
                total={materials_data?.total}
                defaultPageSize={10}
              />
            </>
          ) : (
            <div className="no-result">
              <img src={Image.NoResult} alt="no-result-icon" />
              <Text size="xxl" color="gray2">
                Sorry, No Book Found!
              </Text>
            </div>
          )}
        </section>
      </Container>
    </StyledSearchResult>
  );
};
