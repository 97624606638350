import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";

import AppRoute from './router'
import { GlobalStyle } from "../theme/common/global";
import { Main, Header, Footer } from "../component/common";
const AppLayout = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Header />
      <Main>
        <AppRoute />
      </Main>
      <Footer />
    </BrowserRouter>
  )
}

export default AppLayout