import styled from "styled-components";
import Image from "../../../asset/image";
import { color } from "../../attribute";

export const StyledBooklist = styled.div`
  h1.title {
    text-transform: capitalize;
    margin-bottom: 10px;
  }
  .summary-con {
    margin-bottom: 20px;
    p {
      margin-bottom: 8px;
    }
  }
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .field-wrapper {
      width: 30%;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
      .field-wrapper {
        width: 50%;
      }
    }
    @media (max-width: 425px) {
      .field-wrapper {
        width: 100%;
      }
    }
  }
  .filter-sec {
    margin-bottom: 30px;
    select {
      padding: 10px 16px;
      min-width: 10px;
      height: auto;
      appearance: none;
      -webkit-appearance: none; /* Safari and Chrome */
      -moz-appearance: none; /* Firefox */
      background-image: url(${Image.DownArrow});
      background-repeat: no-repeat;
      background-position: right 10px center; /* move the arrow to the right */
      padding-right: 30px; /* add some space to the right of the text */
    }
    @media (max-width: 991px) {
      row-gap: 20px;
      flex-direction: column;
    }
    @media(max-width: 600px){
      select {
        padding: 10px;
        height: auto;
        font-size: 14px;
        appearance: none;
        -webkit-appearance: none; /* Safari and Chrome */
        -moz-appearance: none; /* Firefox */
        background-image: url(${Image.DownArrow});
        background-repeat: no-repeat;
        background-position: right 10px center; /* move the arrow to the right */
        padding-right: 30px; /* add some space to the right of the text */
      }
      select.author-input{
        width: 105px;
      }
    }
  }
  .filter-wrap,
  .sort-by {
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .booklist-sec {
    .row {
      row-gap: 30px;
    }
    margin-bottom: 30px;
  }
  .no-result {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
    gap: 2rem;
    img {
      width: 100px;
    }
  }
`;
