import styled from "styled-components";
import { color, generalVariable } from "../../attribute";

export const StyledCardContainer = styled.div`
  display: flex;
  padding: 15px;
  border: 1px solid
    ${(props) =>
      color[props?.borderColor] || props?.borderColor || color.border};
  border-radius: ${(props) =>
    generalVariable[props?.cardBorderRadius] || generalVariable.sm}px;
  background-color: ${(props) =>
    color[props?.bgColor] || props?.bgColor || color?.light};
`;
