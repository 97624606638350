export const color = {
  primary: "#191396",
  primary_light: "#8C89CB",
  text: "#000000DE",
  dark: "#000000DE",
  dark2: "#343A40",
  dark3: "#000000DD",
  dark4: "#000",
  light: "#fff",
  gray: "#F5F5F5",
  gray1: "#00000029",
  gray2: "#666666",
  gray3: "#00000066",
  gray4: "#00000099",
  line: "#0000001E",
  line2: "#0000001F",
  border: "#0000001E",
  danger: "#ff0000e6",
  success: "#909613",
  warn: "#FF6F00",
  warn_light: "#FFE3CE",
};
