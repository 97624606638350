import { React, useState } from "react";
import { StyledFooter } from '../../../theme/common/layout/footer'
import { useLocation } from "react-router-dom";
import { Text } from "../typo";
import { Container } from "./container";

export const Footer = () => {
    const location = useLocation();

    return (
        <StyledFooter style={{ display: location.pathname === '/user/signin' ? 'none' : '' }}>
            <Container className="container">
                <Text size='sm'>© {new Date().getFullYear()} NRDC Library. All Rights Reserved. Powered by <Text as='a' href='https://www.innovixdigital.com/' target='_blank' size='sm' color='success'>Innovix Digital</Text></Text>
            </Container>
        </StyledFooter>
    )
}