import React from "react";
import { BsArrowRightShort } from 'react-icons/bs'

import { Text } from "../../common";
import { StyledCategoryCard } from "../../../theme";

export const CategoryCard = ({icon,title,arrow, onClick}) => {
    return(
        <StyledCategoryCard onClick={onClick}>
            <div className="d-flex align-items-center gap-3">
                {icon &&
                    <img src={icon} alt='icon'/>
                }
                {
                    title &&
                    <Text size='lg'>{title}</Text>
                }
            </div>
            {
                arrow &&
                <BsArrowRightShort size={25} color='#666666' className="arrow"/>
            }
        </StyledCategoryCard>
    )
}