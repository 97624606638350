import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RouteConfig } from "./config";
import { useSelector, useDispatch } from "react-redux";
import { useHandleAuth, useToast, useHandleOther } from "../hook";

export const PrivateRoute = ({ children }) => {
  const { auth } = useSelector(state => state)
  if (!auth?.login_data && !auth?.login_data?.user_id) {
    return <Navigate to="/user/signin" />
  }
  return children
};

const RouteList = () => {
  return (
    <Routes>
      {RouteConfig.map(({ path, element, protect }, key) => {
        return (
          <Route
            path={path}
            key={key}
            element={
              protect ? (
                <PrivateRoute protect={protect}>{element}</PrivateRoute>
              ) : (
                element
              )
            }
          />
        );
      })}
    </Routes>
  )
}

const AppRoute = () => {
  const auth_state = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [CheckToken, setCheckToken] = useState(true)
  const { auth } = useHandleAuth()
  const { showToast } = useToast()
  const { translate } = useHandleOther()

  useEffect(() => {
    if (auth_state?.login_data?.user_id && CheckToken === true) {
      dispatch(auth.profileAction())
      setCheckToken(false)
    }
    if (auth_state?.profile_data?.error === 'access token is expired or invalid') {
      showToast({
        title: translate('access-token-expire-or-invalid', 'access-token-expire-or-invalid'),
        titleStyle: { textAlign: 'center' },
        placement: 'bottom',
        btnText: translate('sign-in', 'Sign In'),
        route: '/user/signin',
        alway: true
      })
      dispatch(auth.logoutAction())
    }
  }, [auth_state?.profile_data?.error])

  return (
    <>
      <RouteList />
    </>
  )
}

export default AppRoute