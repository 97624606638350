import { useSelector } from "react-redux";
import product from "../../services/actions/product";

export const useProduct = () => {
  const product_state = useSelector(state => state.product)

  return {
    ...product_state,
    product
  }
}