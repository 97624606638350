import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Image from "../../../asset/image";
import "./style.scss";


export const Loading = (loadstate) => {
  const SITE_DATA = useSelector((state) => state);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    for (const key in SITE_DATA) {
      if (Object.hasOwnProperty.call(SITE_DATA, key)) {
        if (
          SITE_DATA[key]?.isLoading === true ||
          SITE_DATA[key]?.isLoading === undefined ||
          SITE_DATA[key]?.isLoading !== false
        ) {
          setStatus(true);
        } else {
          setTimeout(() => {
            setStatus(false);
          }, 500);
        }
      }
    }
  }, [
    // SITE_DATA.auth?.isLoading,
    // SITE_DATA.product?.isLoading,
    // SITE_DATA.other?.isLoading,
  ]);
  return (
    <>
      {(loadstate?.loadstate || status) === true && (
        <div
          className="loading-com"
          style={{
            position: "fixed",
            zIndex: 99999999999,
            transition: "opacity .3s",
            backgroundColor: "#fff",
            margin: "0",
            top: "0",
            right: "0",
            bottom: " 0",
            left: "0",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="img-fluid"
              src={Image.Logo}
              alt="loading-image"
              width="160px"
            />
            <div className="loading__bar"></div>
          </div>
        </div>
      )}
    </>
  );
};
