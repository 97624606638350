import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";

import {
  Container,
  Text,
  CategoryCard,
  InputCom,
  ButtonCom,
  SearchCom,
} from "../../component";
import { StyledSubcategory } from "../../theme";
import Image from "../../asset/image";
import { useProduct } from "../../hook";

export const SubCategory = () => {
  const { product, categoryDetail_data, isLoading } = useProduct();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const fieldName = params.get("name");
  const fieldId = params.get("field-id");
  const fieldSubName = params.get("sub-name");

  const category = location?.state?.category || "";

  const handleNavigate = (route, state) => {
    navigate(`${route}`, {
      replace: true,
      state: state,
    });
    window.location.reload();
  };

  const handleBack = () => {
    if (!categoryDetail_data[0].parent_id) {
      navigate("../");
    } else {
      navigate(
        `./?name=sub-category&field-id=${categoryDetail_data[0].parent_id}&sub-name=${handleName(fieldSubName)}`
      );
    }
  };

  const handleText = (text) => {
    let name = text;
    let arr = name.split("/");
    return arr[arr.length > 0 ? arr.length - 1 : 0];
  };

  const handleName = (text) => {
    let name = text;
    let arr = name.split("/");
    if (arr.length > 0) {
      let res = arr.slice(0, -1);
      return res.join(" / ");
    }
    return arr[0];
  };

  useEffect(() => {
    dispatch(product.getCategoryDetailAction({ lang: "en", id: fieldId }));
  }, [fieldId]);

  return (
    <>
      {!isLoading && categoryDetail_data && (
        <StyledSubcategory>
          <Container className="container">
            {categoryDetail_data[0]?.name && (
              <Text size="xl" weight="xl" className="title">
                {fieldSubName || ""}
              </Text>
            )}
            <div className="search-wrap">
              <Text>{categoryDetail_data[0]?.desc || ""}</Text>
              <SearchCom />
            </div>
            <hr />
            {categoryDetail_data[0]?.sub_category && (
              <div className="row subcategory-wrap gap-3 gap-md-0">
                {categoryDetail_data[0]?.sub_category?.map((item, key) => (
                  <div className="col-lg-4 col-md-6 col-12" key={key}>
                    {item.has_child ? (
                      <CategoryCard
                        icon={Image.BookIcon}
                        title={item.name}
                        arrow={true}
                        onClick={() =>
                          handleNavigate(
                            `./?name=sub-category&field-id=${
                              item.id
                            }&sub-name=${
                              fieldSubName + " " + "/" + " " + item.name
                            }`
                          )
                        }
                      />
                    ) : (
                      <CategoryCard
                        icon={Image.BookIcon}
                        title={item.name}
                        arrow={true}
                        onClick={() =>
                          navigate(
                            `/book?name=category&field-id=${item.id}&sub-name=${
                              fieldSubName + " " + "/" + " " + item.name
                            }`
                          )
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            <ButtonCom
              color="text"
              bgcolor="gray"
              text={`Back to ${handleText(fieldSubName)}`}
              SVGL={<BsArrowLeftShort color="#666666" size={25} />}
              btnBorderRadius="xxxxl"
              onClick={handleBack}
            />
          </Container>
        </StyledSubcategory>
      )}
    </>
  );
};
