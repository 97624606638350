import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { ButtonCom, Text } from "../../component";
import { useNavigate } from "react-router-dom";
import Image from "../../asset/image";
import { StyledSliderCom } from "../../theme";

const Slider = ({ sliderData }) => {
  const navigate = useNavigate();
  const [mediaWidth, setMediaWidth] = useState(window.innerWidth);
  const handleString = (text) => {
    const string = text;
    let data = string.substring(0, 180);
    if (string.length > 180) {
      data += " ...";
    }
    return data;
  };
  useEffect(() => {
    // Update the windowWidth state when the window is resized
    const handleResize = () => {
      setMediaWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <StyledSliderCom>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="mySwiper mb-5"
      >
        {sliderData?.map((data, i) => (
          <SwiperSlide key={i}>
            <section
              className="announcement-sec"
              style={
                mediaWidth > 700
                  ? { backgroundImage: `url(${Image.AnnoBanner})` }
                  : { background: "#e9e8ff" }
              }
            >
              <div className="wrap">
                <Text size="xxl" weight="xl" color="primary">
                  {data.title}
                </Text>
                <Text>
                  {data?.summary?.length > 250
                    ? handleString(data?.summary)
                    : data?.summary}
                </Text>
                <ButtonCom
                  color="light"
                  bgcolor="primary"
                  btnBorderRadius="xxxl"
                  btnHeight="30px"
                  fontSize="sm"
                  onClick={() =>
                    navigate(`announcement`, {
                      state: {
                        title: data.title,
                        description: data.description,
                        announcement_date: data.announcement_date,
                      },
                    })
                  }
                >
                  More Detail
                </ButtonCom>
              </div>
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSliderCom>
  );
};

export default Slider;
