import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiHandler } from '../../controller/config'
import { isPendingAction, isFulfilledAction, isRejectedAction } from '../typehandle.action'

export const getDictionaryAction = createAsyncThunk("otherSlice/dictionary",
  async (dispatch, getState) => {
    return apiHandler('getDictionary').then(res => res)
  }
)

export const getAnnouncementAction = createAsyncThunk("otherSlice/announcement",
  async (dispatch, getState) => {
    return apiHandler('getAnnouncement').then(res => res)
  }
) 

export const getBannerAction = createAsyncThunk("otherSlice/banner",
  async (dispatch, getState) => {
    return apiHandler('getBanner').then(res => res)
  }
)

export const getNewsCategoryIdAction = createAsyncThunk("otherSlice/newsCategoryId",
  async (dispatch, getState) => {
    return apiHandler('getNewsCategoryId').then(res => res)
  }
)

const otherSlice = createSlice({
  name: 'otherSlice',
  initialState: {
    isLoading: false,
    status: null,
    error: null,
    langStore: {
      lang: 'English',
      code: 'en',
      // lan_icon: Icon.LanguageImg,
    },
  },
  reducers: {
    setLangStore: (state, action) => ({
      ...state,
      langStore: action.payload,
    }),
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction('otherSlice/'), state => {
        return {
          ...state,
          isLoading: true,
          status: 'loading',
          error: null,
        }
      })
      .addMatcher(isFulfilledAction('otherSlice/'), (state, action) => {
        let tmp = action.type.split('/')
        return {
          ...state,
          [tmp[1] + '_data']: action.payload,
          isLoading: false,
          status: 'success',
          error: null,
        }
      })
      .addMatcher(isRejectedAction('otherSlice/'), (state, action) => {
        return {
          ...state,
          isLoading: false,
          status: 'failed',
          error: action.payload,
        }
      })
  },
})

export default {
  otherSlice: otherSlice.reducer,
  setLangStore: otherSlice.actions.setLangStore,
  getDictionaryAction,
  getAnnouncementAction,
  getBannerAction,
  getNewsCategoryIdAction
}