import axios from "axios";

let baseUrl = {
  base_url: {
    // prod: "http://192.168.111.56:8069/api/",
    prod: "http://elibrary-admin.innovixdigital.com/api/",
    dev: "http://elibrary-admin.innovixdigital.com/api/",
  },
};

axios.defaults.baseURL =
  baseUrl.base_url[window.location.hostname === "localhost" ? "dev" : "prod"];

export const http = (props) => axios({ ...props }).then((res) => res.data);
