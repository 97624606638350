import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../../controller/config";
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
} from "../typehandle.action";

export const getMaterialAction = createAsyncThunk(
  "productSlice/materials",
  async (dispatch, getState) => {
    return apiHandler("getMaterials", dispatch).then((res) => res);
  }
);

export const removeMaterialAction = createAsyncThunk(
  "productSlice/materials",
  async () => {
    return null;
  }
);

export const getMaterialDetailAction = createAsyncThunk(
  "productSlice/materialDetail",
  async (dispatch, getState) => {
    let { id, brandid, ...field } = dispatch;
    return apiHandler("getMeterialDetail", field, { id, brandid });
  }
);

export const getAuthorsAction = createAsyncThunk(
  "productSlice/authors",
  async (dispatch, getState) => {
    return apiHandler("getAuthors", dispatch).then((res) => res);
  }
);

export const getAuthorDetailAction = createAsyncThunk(
  "productSlice/authorDetail",
  async (dispatch, getState) => {
    let { id, brandid, ...field } = dispatch;
    return apiHandler("getAuthorDetail", field, { id, brandid });
  }
);

export const getPublishersAction = createAsyncThunk(
  "productSlice/publishers",
  async (dispatch, getState) => {
    return apiHandler("getPublisher", dispatch).then((res) => res);
  }
);

export const getPublisherDetailAction = createAsyncThunk(
  "productSlice/publisherDetail",
  async (dispatch, getState) => {
    let { id, brandid, ...field } = dispatch;
    return apiHandler("getPublisherDetail", field, { id, brandid });
  }
);

export const getCategoryAction = createAsyncThunk(
  "productSlice/category",
  async (dispatch, getState) => {
    return apiHandler("getCategory", dispatch).then((res) => res);
  }
);

export const getCategoryDetailAction = createAsyncThunk(
  "productSlice/categoryDetail",
  async (dispatch, getState) => {
    return apiHandler("getCategoryDetail", dispatch).then((res) => res);
  }
);

export const getAdminRequestAction = createAsyncThunk(
  "authSlice/adminRequest",
  async (dispatch, getState) => {
    let { id } = dispatch;
    return apiHandler("getAdminRequest", dispatch, { id }, "request").then(
      (res) => res
    );
  }
);

export const getRecordAccessLogAction = (data) => {
  let { id } = data;
  return apiHandler("getRecordAccessLog", data, { id }, "access").then(
    (res) => res
  );
};

const productSlice = createSlice({
  name: "productSlice",
  initialState: {
    isLoading: false,
    status: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction("productSlice/"), (state) => {
        return {
          ...state,
          isLoading: true,
          status: "loading",
          error: null,
        };
      })
      .addMatcher(isFulfilledAction("productSlice/"), (state, action) => {
        let tmp = action.type.split("/");
        return {
          ...state,
          [tmp[1] + "_data"]: action.payload,
          isLoading: false,
          status: "success",
          error: null,
        };
      })
      .addMatcher(isRejectedAction("productSlice/"), (state, action) => {
        return {
          ...state,
          isLoading: false,
          status: "failed",
          error: action.payload,
        };
      });
  },
});

export default {
  productSlice: productSlice.reducer,
  getMaterialAction,
  getMaterialDetailAction,
  getAuthorsAction,
  getPublishersAction,
  getCategoryAction,
  getRecordAccessLogAction,
  getAdminRequestAction,
  removeMaterialAction,
  getCategoryDetailAction,
  getAuthorDetailAction,
  getPublisherDetailAction
};
