import React, { useEffect } from "react";

import {
  StyledModal,
  StyledModalDialog,
  StyledModalContent,
  StyledCloseOutside,
  StyledModalClose,
} from "../../../theme";
import { RiCloseFill } from "react-icons/ri";

export const ModalCom = ({
  children,
  isModal,
  handleIsModal,
  isSetTimeOut,
  isCloseBtn = false,
  className,
  restartMedia = null,
  ...props
}) => {
  useEffect(() => {
    isSetTimeOut &&
      setTimeout(() => {
        handleIsModal(true, null);
      }, 5000);
  }, [isSetTimeOut]);

  const handleCloseModal = async () => {
    if (restartMedia) {
      restartMedia();
    }
    handleIsModal(false);
    // window.location.reload();
  };

  return (
    <StyledModal
      className={`fade ${isModal ? "show" : "hide"} ${className}`}
      {...props}
    >
      <StyledCloseOutside
        onClick={handleCloseModal}
        className="close_outside"
      />
      <StyledModalDialog {...props} className="modaldialog">
        {isCloseBtn && (
          <StyledModalClose
            onClick={handleCloseModal}
            className="modal-close-btn"
          >
            <RiCloseFill />
          </StyledModalClose>
        )}
        <StyledModalContent {...props} className="modal-content">
          {children}
        </StyledModalContent>
      </StyledModalDialog>
    </StyledModal>
  );
};
