import React from "react";

import { StyledChangePassword } from "../../theme";
import { Text, FormContainerCom, ButtonCom, InputCom } from "../../component";
import { useHandleAuth } from "../../hook";

export const ChangePassword = () => {
  const { handleChangePassword } = useHandleAuth()

  let initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: ""
  };

  const validationSchema = (yup) =>
    yup.object({
      old_password: yup
        .string()
        .required("Old Password is Required"),
      new_password: yup
        .string()
        .required("New Password is Required"),
      confirm_password: yup
        .string()
        .required("Confirm Password is Required")
        .oneOf(
          [yup.ref("new_password"), null],
          "Password-must-match"
        ),
    });

  const onDataSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setTimeout(async () => {
      let post_data = {
        old_password: values?.old_password,
        new_password: values?.new_password,
      };
      handleChangePassword(post_data);
      actions.setSubmitting(false);
      actions.resetForm();
    }, 1000);
  };


  return (
    <StyledChangePassword className="container">
      <Text as='h1' weight='lg' size='xxl' textAlign='center'>Change Password</Text>
      <div className="form-wrap">
        <FormContainerCom
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            onDataSubmit(values, actions)
          }
        >
          {(formikProps) => (
            <>
              <InputCom
                label="Old Password"
                name="old_password"
                type="password"
              />
              <InputCom
                label="New Password"
                name="new_password"
                type="password"
              />
              <InputCom
                label="Confirm Password"
                name="confirm_password"
                type="password"
              />
              <div className="form_foot_sec d-flex">
                <ButtonCom
                  color="text"
                  bgcolor="light"
                  btnBorderRadius="xxxs"
                  text="CANCEL"
                  onClick={(e) => formikProps.resetForm()}
                />
                <ButtonCom
                  type="submit"
                  color="light"
                  bgcolor="primary"
                  btnBorderRadius="xxxl"
                  text="UPDATE"
                />
              </div>
            </>
          )}
        </FormContainerCom>
      </div>
    </StyledChangePassword>
  )
}