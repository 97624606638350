import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledProfile } from "../../theme";
import { Container, Text } from "../../component";
import Image from "../../asset/image";
import { useHandleAuth } from "../../hook";
import { textToUpper } from "../product";

export const Profile = () => {
  const { auth, profile_data } = useHandleAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(auth.profileAction());
  }, []);

  return (
    <Container className="container">
      <div className="row">
        <div className="col-lg-10 col-12 m-auto">
          <StyledProfile className="row">
            <div className="col-lg-4 profile-image-con d-flex flex-column">
              {profile_data?.image && (
                <img
                  src={profile_data?.image || Image.UploadProfile}
                  alt="upload-profile-image"
                />
              )}
              <Text
                as="a"
                href="/user/change_password"
                textDecoration="underline"
                color="primary"
              >
                CHANGE PASSWORD
              </Text>
            </div>
            <div className="col-lg-4 info mt-3">
              {profile_data?.name && (
                <>
                  <Text as="span" color="gray4">
                    NAME
                  </Text>
                  <Text weight="xl">{profile_data?.name}</Text>
                </>
              )}
              {profile_data?.seat_no && (
                <>
                  <Text as="span" color="gray4">
                    ROLL NO.
                  </Text>
                  <Text weight="xl">{profile_data?.seat_no}</Text>
                </>
              )}
              {profile_data?.id && (
                <>
                  <Text as="span" color="gray4">
                    ID
                  </Text>
                  <Text weight="xl">{profile_data?.id}</Text>
                </>
              )}
              {profile_data?.class && (
                <>
                  <Text as="span" color="gray4">
                    CLASS
                  </Text>
                  <Text weight="xl">{profile_data?.class}</Text>
                </>
              )}
              {profile_data?.major && (
                <>
                  <Text as="span" color="gray4">
                    MAJOR
                  </Text>
                  <Text weight="xl">{profile_data?.major}</Text>
                </>
              )}
            </div>
            <div className="col-lg-4 other-info">
              {profile_data?.register_no && (
                <>
                  <Text as="span" color="gray4">
                    REGISTER NO.
                  </Text>
                  <Text weight="xl">{profile_data?.register_no}</Text>
                </>
              )}
              {profile_data?.gender && (
                <>
                  <Text as="span" color="gray4">
                    GENDER
                  </Text>
                  <Text weight="xl">{textToUpper(profile_data?.gender)}</Text>
                </>
              )}
              {profile_data?.phone && (
                <>
                  <Text as="span" color="gray4">
                    PHONE
                  </Text>
                  <Text weight="xl">{profile_data?.phone}</Text>
                </>
              )}
              {profile_data?.email && (
                <>
                  <Text as="span" color="gray4">
                    EMAIL
                  </Text>
                  <Text weight="xl">{profile_data?.email}</Text>
                </>
              )}
            </div>
          </StyledProfile>
        </div>
      </div>
    </Container>
  );
};
