import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useProduct } from "../../../hook";
import { InputCom } from "../form";

export const SearchCom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { product } = useProduct();
  const [bookname, setBookname] = useState("");

  const handleSearch = () => {
    let req_obj = {
      search: bookname,
    };
    bookname && dispatch(product.getMaterialAction(req_obj));
    bookname !== "" && navigate(`/search/${bookname}`);
    setBookname("");
    };
    
  return (
    <InputCom
      isValidate={false}
      placeholder="Search book name"
      type="text"
      value={bookname}
      onChange={(e) => {
        setBookname(e.target.value);
      }}
      onKeyPress={(e) => e.key === "Enter" && handleSearch()}
    />
  );
};
