export const routes = {
  login: "auth/token",

  getDictionary: {
    method: "get",
    endpoint: "dictionary",
    access_token: true,
  },
  getAnnouncement: {
    method: "get",
    endpoint: "announcement",
    access_token: true,
  },

  getBanner: {
    method: "get",
    endpoint: "image/banner",
    access_token: false,
  },

  getNewsCategoryId: {
    method: "get",
    endpoint: "application/meta",
    access_token: true,
  },

  //product routes
  getMaterials: {
    method: "get",
    endpoint: "library/get/material",
    access_token: true,
  },

  getMeterialDetail: {
    method: "get",
    endpoint: "library/get/material/:id",
    access_token: true,
  },

  getAuthors: {
    method: "get",
    endpoint: "author",
    access_token: true,
  },

  getAuthorDetail: {
    method: "get",
    endpoint: "author/:id",
    access_token: true,
  },

  getPublisher: {
    method: "get",
    endpoint: "publisher",
    access_token: true,
  },

  getPublisherDetail: {
    method: "get",
    endpoint: "publisher/:id",
    access_token: true,
  },

  getCategory: {
    method: "get",
    endpoint: "category",
    access_token: true,
  },

  getCategoryDetail: {
    method: "get",
    endpoint: "category",
    access_token: true,
  },

  //auth routes
  getAccessHistory: {
    method: "get",
    endpoint: "user/access/history",
    access_token: true,
  },

  getRecordAccessLog: {
    method: "post",
    endpoint: "library/material/:id",
    access_token: true,
  },

  getAdminRequest: {
    method: "post",
    endpoint: "material/:id",
    access_token: true,
  },

  login: {
    method: "get",
    endpoint: "auth/token",
  },

  logout: {
    method: "delete",
    endpoint: "auth/token",
    access_token: true,
  },

  changePassword: {
    method: "post",
    endpoint: "password/change",
    access_token: true,
  },

  profile: {
    method: "get",
    endpoint: "user",
    access_token: true,
  },
};
