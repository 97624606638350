import styled from "styled-components";
// import { rgba } from 'polished'
import { color, fontSize, generalVariable } from "../../attribute";

//* start button component
export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size:${(props) => fontSize[props.fontSize] || 16}px;
  border-radius: ${(props) =>
    generalVariable[props?.btnBorderRadius] || props?.btnBorderRadius}px;
  padding: 10px 15px;
  width: max-content;
  min-width: ${(props) => `${props.btnWidth}px` || "max-content"};
  min-height: ${(props) => props.btnHeight || 50}px;
  color: ${(props) => color[props?.color] || props?.color || color.dark};
  background-color: ${(props) =>
    color[props?.bgcolor] || props?.bgcolor || color?.primary};
  border: ${(props) => (props?.type === "outline" ? 1 : 0)}px solid
    ${(props) =>
      color[props?.borderColor || "transparent"] || props?.borderColor};
  border: ${(props) => (props?.btntype === "outline" ? 1 : 0)}px solid
    ${(props) =>
      color[props?.borderColor || "transparent"] || props?.borderColor};
  transition: background-color 0.15s ease-in-out;
  gap: 10px;
  &:hover {
    background-color: ${(props) => {
      if (props?.type === "outline") {
        return color[props?.hoverColor] || props?.hoverColor || color.gray3;
      } else {
        /* return rgba(color?.button?.[props?.bgcolor] || props?.bgcolor || color?.button?.primary, 0.7) */
      }
    }};
  }
  &.btn-disabled {
  }
  &.btn-link {
    background: transparent;
    min-width: 0;
    min-height: 0;
  }
  opacity: ${(props) => (props?.disabled === true ? "0.5" : "1")};
`;

export const StyledBtnSvgL = styled.div`
  // margin-right: 15px;
`;

export const StyledBtnSvgR = styled.div`
  /* margin-right: 15px; */
`;
//* end button component
