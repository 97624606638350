import styled from "styled-components";
import { color } from "../../attribute";

export const StyledCategoryCard = styled.div`
  border: 1px solid ${color.border};
  border-radius: 15px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .arrow {
    transform: translateX(-5px);
    transition: all 0.2s ease-in;
  }
  &:hover {
    box-shadow: 0px 2px 4px #00000029;
    .arrow {
      transform: translateX(5px);
    }
  }
`;
