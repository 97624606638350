import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../../controller/config";
import {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
} from "../typehandle.action";

let initialState = {
  isLoading: false,
  status: null,
  error: null,
};

export const loginAction = createAsyncThunk(
  "authSlice/login",
  async (dispatch, getState) => {
    return apiHandler("login", dispatch).then((res) => res);
  }
);

export const logoutAction = createAsyncThunk(
  "authSlice/logout",
  async (dispatch, thunkAPI) => {
    return apiHandler("logout").then((res) => {
      thunkAPI.dispatch(authSlice.actions.clearAuthStore());
      return res;
    });
  }
);

export const changePasswordAction = createAsyncThunk(
  "authSlice/changePassword",
  async (dispatch, getState) => {
    return apiHandler("changePassword", dispatch).then((res) => res);
  }
);

export const profileAction = createAsyncThunk(
  "authSlice/profile",
  async (dispatch, getState) => {
    return apiHandler("profile").then((res) => res);
  }
);

export const getAccessHistoryAction = createAsyncThunk(
  "authSlice/accessHistory",
  async (dispatch, getState) => {
    return apiHandler("getAccessHistory", dispatch).then((res) => res);
  }
);

const authSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    clearAuthStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction("authSlice/"), (state) => {
        return {
          ...state,
          isLoading: true,
          status: "loading",
          error: null,
        };
      })
      .addMatcher(isFulfilledAction("authSlice/"), (state, action) => {
        let tmp = action.type.split("/");
        return {
          ...state,
          [tmp[1] + "_data"]: action.payload,
          isLoading: false,
          status: "success",
          error: null,
        };
      })
      .addMatcher(isRejectedAction("authSlice/"), (state, action) => {
        return {
          ...state,
          isLoading: false,
          status: "failed",
          error: action.payload,
        };
      });
  },
});

export default {
  authSlice: authSlice.reducer,
  loginAction,
  logoutAction,
  profileAction,
  getAccessHistoryAction,
  changePasswordAction,
};
