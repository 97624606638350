import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import React, { useEffect } from "react";
import AppLayout from "./router";
import { ToastCom, Loading } from "./component";

const App = () => {
  return (
    <>
      <ToastCom />
      <Loading />
      <AppLayout />
    </>
  );
};

export default App;
