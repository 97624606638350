import React from "react";
import { useField } from "formik";

import { StyledInput } from "../../../theme";
import { FieldWrapperCom } from "./fieldwrapper";


export const InputCom = ({
  isValidate = true,
  checked,
  onChange,
  ref,
  ...props
}) => {
  return isValidate ? (
    <ValidateInput {...props} />
  ) : (
    <FieldWrapperCom {...props}>
      <StyledInput
        // id={props?.id || props?.name}
        as={props?.input_as}
        onChange={onChange}
        ref={ref}
        checked={checked}
        {...props}
      />
    </FieldWrapperCom>
  );
};

export const ValidateInput = (props) => {
  const [field, meta] = useField(props);

  let fieldWrapperComProps = {
    ...props,
    field,
    meta,
  };

  return (
    <FieldWrapperCom className="vlc_con" {...fieldWrapperComProps}>
      <StyledInput
        id={props?.id || props?.name}
        className={`${meta.touched && meta.error ? "error" : ""}`}
        as={props?.input_as}
        {...field}
        {...props}
      />
    </FieldWrapperCom>
  );
};
