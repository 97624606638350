import React from "react";

import { StyledButton, StyledBtnSvgL, StyledBtnSvgR } from "../../../theme";
import { Text } from "../typo";

export const ButtonCom = ({
  children,
  SVGL,
  SVGR,
  text,
  onClick,
  ...props
}) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children ? (
        children
      ) : (
        <>
          {SVGL && <StyledBtnSvgL {...props}>{SVGL}</StyledBtnSvgL>}
          <Text {...props}>{text}</Text>
          {SVGR && <StyledBtnSvgR {...props}>{SVGR}</StyledBtnSvgR>}
        </>
      )}
    </StyledButton>
  );
};

ButtonCom.defaultProps = {
  bgcolor: "primary",
  color: "text",
  type: "full",
};
