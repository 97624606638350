export const fontSize = {
  hl: 60,
  xxxl: 32,
  xxl: 28,
  xl: 20,
  lg: 18,
  md: 16,
  sm: 14,
  xs: 12,
  xxs: 10,
  xxxs: 8,
};

export const fontWeight = {
  xxl: 900,
  xl: 700,
  lg: 600,
  md: 500,
  sm: 400,
  xs: 300,
};

export const fontFamily = {
  Poppins: `'Poppins', sans-serif`,
};
