import styled from "styled-components";
import { color } from "../../attribute";

export const StyledSearchCom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  .title {
    margin-bottom: 20px;
  }
  .field-wrapper {
    width: 100%;
    input {
      padding: 12px 16px;
    }
  }
  button {
    width: 40%;
    max-width: 374px;
    padding-block: 12px;
  }
  .radio-con {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 15px;
    @media (max-width: 991px) {
      gap: 10px;
      justify-content: flex-start;
    }
    .field-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      width: max-content;
      align-items: center;
      .label {
        margin-left: 8px;
        margin-bottom: 0;
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    @media(max-width: 600px){
      .field-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: max-content;
        align-items: center;
        padding : 0 15px;
        width: 45%;
        .label {
          margin-left: 8px;
          margin-bottom: 0;
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }
`;
