import React from "react";
import { useNavigate } from "react-router-dom";

import { CardCom,Text } from "../../common";
import { StyledBookCard } from "../../../theme";
import Image from "../../../asset/image";
import { textToUpper } from "../../../screen/product";

export const BookCard =({children,...props})=>{
    return(
        <StyledBookCard onClick={props?.onClick}>
            <CardCom cardBorderRadius='md' className='card'>
                {children?children
                :
                <div className="wrap d-flex">
                    <img src={props?.image || Image.BookDefault} alt='' className="book-image"/>
                    <div className="desc-con">
                        {props?.title &&
                        <Text className='title' weight='xxl'>{props?.title}</Text>
                        }
                        {props?.summary &&
                        <Text className='summary' weight='xxl'>{props?.summary}</Text>
                        }
                        {
                            props?.subject &&
                            <div className="d-flex align-items-center"><Text as='span' size='sm' color='gray4'>Subject Domain :</Text><Text>{props?.subject}</Text></div>
                        }
                        {
                            props?.language &&
                            <div className="d-flex align-items-center"><Text as='span' size='sm' color='gray4'>Language :</Text><Text>{props?.language}</Text></div>
                        }
                        {
                            props?.author &&
                            <div className="d-flex align-items-center"><Text as='span' size='sm' color='gray4'>Author :</Text><Text>{props?.author}</Text></div>
                        }
                        {
                            props?.publisher &&
                            <div className="d-flex align-items-center"><Text as='span' size='sm' color='gray4'>Publisher :</Text><Text>{props?.publisher}</Text></div>
                        }
                        {
                            props?.type &&
                            <div className="d-flex align-items-center"><Text as='span' size='sm' color='gray4'>File Format :</Text><Text>{textToUpper(props?.type)}</Text></div>
                        }
                    </div>
                </div>
                }
            </CardCom>
        </StyledBookCard>
    )
}