import styled from "styled-components";

export const StyledHome = styled.div`
  section {
    &:not(:last-child) {
      margin-bottom: 50px;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }
  }
  hr {
    margin-block: 50px;
    @media (max-width: 991px) {
      margin-block: 30px;
    }
  }
  .announcement-sec {
    min-height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .wrap {
      width: 70%;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      button {
        padding-inline: 50px;
      }
      @media (max-width: 991px) {
        width: 100%;
        align-items: center;
      }
      @media (max-width: 600px) {
        p{
          padding: 0 8px;
        }
      }
    }
  }
  .search-sec {
    @media (min-width: 1201px) {
      width: 70%;
      margin: auto;
    }
  }
  .category-wrap {
    margin-top: 30px;
  }
`;
