import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Text, InputCom, ButtonCom } from "..";
import Image from "../../../asset/image";
import { useHandleOther, useProduct } from "../../../hook";
import { StyledSearchCom } from "../../../theme";

export const SearchFilterCom = () => {
  const { product } = useProduct();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [keyword, setKeyword] = useState("");
  const [radioselect, setradioSelect] = useState(
    location?.state?.filter ? location.state.filter : "book name"
  );

  const handleSearch = () => {
    keyword !== "" &&
      navigate(`/search/${keyword}`, {
        state: { filter: radioselect !== "" ? radioselect : "book name" },
      });
    setKeyword("");
    window.location.reload();
  };

  return (
    <StyledSearchCom>
      <Text className="title" weight="xl" size="xxxl">
        Find the book you need here
      </Text>
      <div className="radio-con row d-flex align-items-center">
        <InputCom
          isValidate={false}
          type="radio"
          label="Book"
          value="book name"
          id="book"
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "book name" ? true : false}
        />
        <InputCom
          isValidate={false}
          type="radio"
          label="Author"
          value="author"
          id='author'
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "author" ? true : false}
        />
        <InputCom
          isValidate={false}
          type="radio"
          label="Publisher"
          value="publisher"
          id="publisher"
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "publisher" ? true : false}
        />
        <InputCom
          isValidate={false}
          type="radio"
          label="Accession Number"
          value="accession number"
          id="accession_number"
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "accession number" ? true : false}
        />
        <InputCom
          isValidate={false}
          type="radio"
          label="ISBN"
          value="isbn"
          id="isbn"
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "isbn" ? true : false}
        />
        <InputCom
          isValidate={false}
          type="radio"
          label="Publication Year"
          value="publication year"
          id="publication_year"
          onChange={(e) => setradioSelect(e.target.value)}
          checked={radioselect === "publication year" ? true : false}
        />
      </div>
      <InputCom
        isValidate={false}
        placeholder="Enter keywords"
        type="text"
        value={keyword}
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onKeyPress={(e) => e.key === "Enter" && handleSearch()}
      />
      <ButtonCom
        color="light"
        bgcolor="primary"
        btnBorderRadius="xxxl"
        onClick={() => handleSearch()}
      >
        SEARCH
      </ButtonCom>
    </StyledSearchCom>
  );
};
