import { React, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdOutlineClose, MdOutlineLogout } from "react-icons/md";
import { FiUser } from "react-icons/fi";

import Image from "../../../asset/image";
import { Text } from "../typo";
import { Container } from "./container";
import { StyledHeaderCom } from "../../../theme/common/layout";
import { useHandleAuth, useHandleOther } from "../../../hook";
import { useDispatch } from "react-redux";

export const Header = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const fieldSubName = params.get("sub-name");
  const navigate = useNavigate();
  const { handleSignOut } = useHandleAuth();
  const { other, newsCategoryId_data } = useHandleOther();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const { profile_data } = useHandleAuth();
  const ref = useRef(null);
  const activeTag = JSON.parse(localStorage.getItem("nav-tag"));
  const [navTag, setNavTag] = useState(activeTag || "");
  const dispatch = useDispatch();

  useEffect(() => {
    switch (location.pathname) {
      case "/author":
        setNavTag("author");
        break;
      case "/publisher":
        setNavTag("publisher");
        break;
      case "/newspaper":
        setNavTag("newspaper");
        break;

      case "/":
        setNavTag("home");
        handleNavTag("home");
        break;

      case "/user/change_password":
        setNavTag("home");
        break;

      default:
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    let req_obj = {
      code: "news_id",
    };
    dispatch(other.getNewsCategoryIdAction(req_obj));
  }, []);

  const handleNavTag = (name) => {
    if (name == "newspaper") {
      navigate(`/book?name=category&field-id=${newsCategoryId_data?.news_id?.text_one}&sub-name=Newspaper`);
    }
    setNavTag(name);
    localStorage.setItem("nav-tag", JSON.stringify(name));
  };

  useEffect(() => {
    if (fieldSubName === "Newspaper") {
      handleNavTag("newspaper");
    }
  },[fieldSubName])

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <StyledHeaderCom
      style={{ display: location.pathname === "/user/signin" ? "none" : "" }}
    >
      <Container className="container d-flex justify-content-between align-items-start">
        <div className="burger-menu" onClick={() => setDrawerOpen(!drawerOpen)}>
          <RxHamburgerMenu size={25} />
        </div>
        <ul className={`navigation ${drawerOpen === true ? "open" : ""}`}>
          <li className="close" onClick={() => setDrawerOpen(!drawerOpen)}>
            <MdOutlineClose size={20} />
          </li>
          <li onClick={() => handleNavTag("home")}>
            <Text
              as="a"
              href="/"
              size="lg"
              className={navTag == "home" && "active"}
            >
              Home
            </Text>
          </li>
          <li onClick={() => handleNavTag("author")}>
            <Text
              as="a"
              href="/author"
              size="lg"
              className={navTag == "author" && "active"}
            >
              Author
            </Text>
          </li>
          <li onClick={() => handleNavTag("publisher")}>
            <Text
              as="a"
              href="/publisher"
              size="lg"
              className={navTag == "publisher" && "active"}
            >
              Publisher
            </Text>
          </li>
          <li
            onClick={() => handleNavTag("newspaper")}
            style={{ cursor: "pointer" }}
          >
            <Text
              as="a"
              size="lg"
              className={navTag == "newspaper" && "active"}
            >
              Newspaper
            </Text>
          </li>
        </ul>
        <div className="logo">
          <a href="/">
            <img src={Image.Logo} alt="logo-image" />
          </a>
        </div>
        <div className="profile d-flex align-items-center">
          <img
            src={profile_data?.image ? profile_data.image : Image.Profile}
            alt="profile-image"
            onClick={() => setShow(!show)}
          />
          <Text size="md" onClick={() => setShow(!show)}>
            {profile_data?.name || "Name"}
          </Text>
        </div>
        {show && (
          <div
            className="shadow-sm rounded p-3 dropdown-profile"
            style={{
              position: "absolute",
              top: "100px",
              right: "300px",
              backgroundColor: "white",
              zIndex: "100",
            }}
            ref={ref}
          >
            <div className=" d-flex gap-3">
              <img
                src={profile_data?.image || Image.Profile}
                alt=""
                style={{ width: "60px", height: "60px", borderRadius: "50%" }}
              />
              <div className="">
                <p className=" m-0 fw-bold">{profile_data?.name || "Name"}</p>
                {profile_data?.seat_no && (
                  <p className=" my-1 small text-muted">
                    Roll No. {profile_data?.seat_no}
                  </p>
                )}
                {profile_data?.register_no && (
                  <p className=" m-0 small text-muted">
                    ID: {profile_data?.register_no}
                  </p>
                )}
              </div>
            </div>
            <hr />
            <div
              className=" d-flex align-items-center gap-3 mb-2 user-select-none"
              onClick={() => {
                navigate("user/profile");
                setShow(false);
              }}
            >
              <FiUser size={23} />
              <span>My Profile</span>
            </div>
            <div
              className=" d-flex align-items-center gap-3 user-select-none"
              onClick={() => handleSignOut()}
            >
              <MdOutlineLogout size={23} />
              <span>Logout</span>
            </div>
          </div>
        )}
      </Container>
    </StyledHeaderCom>
  );
};
