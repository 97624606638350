import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import auth from "../../services/actions/auth";
import { useHandleOther } from "../other";
import { useToast } from "../useToast";
import { FiInfo } from "react-icons/fi";
import { Text, CardCom, ButtonCom } from "../../component/common";

export const useHandleAuth = (props) => {
  const auth_state = useSelector(state => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useHandleOther();
  const { showToast, hideToast } = useToast();

  const handleSignIn = async (data) => {
    let res = await dispatch(auth.loginAction(data));
    if (res?.payload?.type === "need_to_verify_otp") {
      /**
       * * show need to verify otp
       */
      showToast({
        alway: true,
        render: (
          <CardCom style={{ padding: "40px", borderRadius: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <FiInfo size={30} color="#333339" />
              <Text textAlign="center" style={{ marginBottom: 20 }}>
                {translate(
                  "you-need-to-verify-the-phone-number-before-login",
                  "You need to verify the phone number before login"
                )}
                .
              </Text>
              <div>
                <ButtonCom
                  text={translate("verify-now", "Verify Now")}
                  color="light"
                  bgcolor="primary_light2"
                  btnBorderRadius="xxxs"
                  style={{ margin: "auto" }}
                  onClick={() => {
                    hideToast();
                    // handleIsExistUser({
                    //   mobile: data?.headers?.login,
                    //   from: "signup",
                    // });
                  }}
                />
              </div>
            </div>
          </CardCom>
        ),
      });
    } else if (res?.payload?.type === "access_denied") {
      /**
       * * show res?.payload?.message
       */
      showToast({
        type: "error",
        title:
          res?.payload?.message ||
          res?.payload?.error ||
          "Something wrong! Please try again.",
        placement: "bottom",
      });
    } else if (res?.payload?.type === "need_to_approve_by_admin") {
      /**
       * * show res?.payload?.message
       */
      showToast({
        type: "error",
        title:
          res?.payload?.message ||
          res?.payload?.error ||
          "Something wrong! Please try again.",
        placement: "bottom",
      });
    } else {
      if (res?.payload?.access_token) {
        /**
         * * go to App
         */
        let res = await dispatch(auth.profileAction(data));
        if (res?.meta?.requestStatus === "fulfilled") await navigate('/');
      } else {
        /**
         * * show res?.payload?.message
         */
        showToast({
          type: "error",
          titleStyle: { color: "dark" },
          title:
            res?.payload?.message ||
            res?.payload?.error ||
            "Something wrong! Please try again.",
          placement: "bottom",
        });
      }
    }
  };

  const handleSignOut = async (data) => {
    await dispatch(auth.logoutAction());
  };

  const handleChangePassword = async (data) => {
    let res = await dispatch(auth.changePasswordAction(data));
    if (res?.payload?.status === "success") {
      /**
       * * show change_password_success message
       */
      showToast({
        type: "success",
        title: translate(
          "change_password_success",
          "Successfully changed password."
        ),
        placement: "bottom",
      });
      navigate('/user/signin');
    } else {
      /**
       * * show res?.payload?.message
       */
      showToast({
        type: "error",
        title: res?.payload?.message || "Something wrong! Please try again.",
        placement: "bottom",
      });
    }
  };

  return {
    ...auth_state,
    auth,
    handleSignIn,
    handleSignOut,
    handleChangePassword
  };
};
