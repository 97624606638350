import styled from "styled-components";
import { color, fontFamily, fontSize, fontWeight } from "../../attribute";

export const StyledHeaderCom = styled.div`
  .navigation {
    width: 40%;
    padding-top: 60px;
    display: flex;
    align-items: center;
    gap: 30px;
    li {
      list-style: none;
      a {
        padding: 8px 16px;
        &.active {
          background: ${color.primary};
          color: ${color.light};
          border-radius: 20px;
        }
        @media (max-width: 1200px) {
          padding-inline: 8px;
          font-size: ${fontSize.md}px!important;
        }
      }
    }
    .close {
      @media (min-width: 992px) {
        display: none;
      }
    }
    @media (max-width: 1600px) {
      gap: 15px;
    }
    @media (max-width: 1400px) {
      gap: 10px;
    }
  }
  .logo {
    padding-top: 26px;
    width: 20%;
    display: flex;
    justify-content: center;
    img {
      @media (max-width: 1200px) {
        max-width: 130px;
      }
    }
  }
  .profile {
    width: 40%;
    padding-top: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    img,
    p {
      cursor: pointer;
    }
    &:hover {
      p {
        color: ${color.primary};
      }
    }
  }
  .dropdown-profile{
    border: 0.1px solid #999;
    span{
      cursor: pointer;
    }
  }
  @media (max-width: 1800px){
    .dropdown-profile{
      right: 120px !important;
    }
  }
  @media (max-width: 1440px){
    .dropdown-profile{
      right: 100px !important;
    }
  }
  @media (max-width: 990px){
    .dropdown-profile{
      top: 80px !important;
      right: 50px !important;
    }
  }
  @media (max-width: 600px){
    .dropdown-profile{
      top: 70px !important;
      right: 30px !important;
    }
  }
  .burger-menu {
    @media (min-width: 992px) {
      display: none;
    }
  }
  @media (max-width: 991px) {
    padding-top: 5px;
    .container {
      align-items: center !important;
    }
    .navigation {
      padding-top: 0;
      flex-direction: column;
      align-items: flex-start;
      background: ${color.gray};
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 9999999;
      width: 90vw;
      height: 100vh;
      padding: 30px 20px;
      gap: 20px;
      transition: all 0.2s ease-in;
      &.open {
        left: 0;
      }
      .close {
        position: absolute;
        right: 20px;
        top: 25px;
      }
    }
    .burger-menu {
      width: 30%;
    }
    .logo {
      width: 40%;
      padding-top: 0;
      img {
        max-width: 60px;
      }
    }
    .profile {
      width: 30%;
      padding-top: 0;
      img {
        max-width: 40px;
        max-height: 40px;
      }
      p {
        display: none;
      }
    }
  }
`;
