import styled from "styled-components";
import { color } from "../../attribute";

export const StyledProfile = styled.div`
  border: 1px solid ${color.border};
  border-radius: 16px;
  padding: 30px;
  .profile-image-con {
    gap: 27px;
    img {
      max-width: 190px;
    }
  }
  p {
    margin-bottom: 20px;
    margin-top: 4px;
  }
`;
