import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Text, Container, CategoryCard, PaginationWrap } from "../../component";
import { StyledAuthor } from "../../theme";
import Image from "../../asset/image";
import { useProduct } from "../../hook";

export const AuthorList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product, authors_data } = useProduct();
  const [language, setLanguage] = useState("en");
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    let req_obj = {
      lang: language,
      page_no: current,
      per_page: authors_data?.total,
    };
    dispatch(product.getAuthorsAction(req_obj));
  }, [language]);

  const char_list =
    language === "en"
      ? [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ]
      : [
          "က",
          "ခ",
          "ဂ",
          "ဃ",
          "င",
          "စ",
          "ဆ",
          "ဇ",
          "ဈ",
          "ဉ",
          "ည",
          "ဋ",
          "ဌ",
          "ဍ",
          "ဎ",
          "ဏ",
          "တ",
          "ထ",
          "ဒ",
          "ဓ",
          "န",
          "ပ",
          "ဖ",
          "ဗ",
          "ဘ",
          "မ",
          "ယ",
          "ရ",
          "လ",
          "ဝ",
          "သ",
          "ဟ",
          "ဠ",
          "အ",
        ];

  //get the authors
  const authors = [];
  const author_chars = [];
  if (authors_data && authors_data?.data) {
    authors_data?.data?.map((item) => {
      let obj = {
        id: item?.id,
        name: item?.name,
        abbreviation: item?.abbreviation,
        biography: item?.biography,
        image: item?.image,
      };
      authors.push(obj);
    });
  }

  // Sort the author list of objects by name
  authors.sort((a, b) => a.name.localeCompare(b.name));

  // Group the objects by first letter of name
  let groups = authors.reduce((acc, obj) => {
    let char = obj.name[0];
    let group = acc.find((g) => g[0]?.name.startsWith(char));
    if (!group) acc.push((group = []));
    group.push({ ...obj, char });
    author_chars.push(char);
    return acc;
  }, []);

  return (
    <StyledAuthor>
      <Container className="container">
        <Text size="xxl" weight="xl">
          Author
        </Text>
        <hr />
        <div className="language-tab">
          <Text
            className={`by-eng ${language === "en" ? "active" : ""}`}
            onClick={() => {
              setLanguage("en");
            }}
          >
            By English Name
          </Text>
          <Text
            className={`by-mm' ${language === "my" ? "active" : ""}`}
            onClick={() => {
              setLanguage("my");
            }}
          >
            By Myanmar Name
          </Text>
        </div>
        <div className="characters d-flex flex-wrap">
          {char_list?.map((e, key) => (
            <Text
              as="a"
              href={`#${e}`}
              key={key}
              className={author_chars.includes(e) ? "" : "disabled"}
            >
              {e}
            </Text>
          ))}
        </div>
        <section className="author-list-sec">
          {groups.map((item, key) => (
            <div className="wrap d-flex" key={key} id={item[0].char}>
              <Text size="xl" weight="xl">
                {item[0].char}
              </Text>
              <div className="names d-flex flex-wrap">
                {item.map((i, key) => (
                  <Text
                    as="a"
                    key={key}
                    onClick={() => {
                      navigate(`/book?name=author&field-id=${i.id}&sub-name=${i?.name}`);
                    }}
                    textDecoration="underline"
                  >
                    {i?.name}
                  </Text>
                ))}
              </div>
            </div>
          ))}
        </section>
      </Container>
    </StyledAuthor>
  );
};
