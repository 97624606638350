import styled from "styled-components";
import { color } from "../../attribute";

export const StyledAudioPlayer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: ${color.gray};
  .play-pause-btn {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .mute-btn {
    padding-inline: 2px;
    svg {
      width: 17px;
      height: 17px;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  .progress-bar {
    width: 50%;
    background-color: rgb(231 231 231);
    .progress {
      height: 4px;
      background-color: ${color.text};
    }
    @media (max-width: 768px) {
      width: 40%;
    }
  }
`;
