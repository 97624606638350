import styled from "styled-components";
import { color } from "../../attribute";
import Image from "../../../asset/image";

const imgUrl = {
  leftArrow: Image.ArrowLeft,
  rightArrow: Image.ArrowRight,
};

export const StyledPagination = styled.div`
  // margin-top: 30px;
  &.hide {
    display: none;
  }
  .rc-pagination {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    li {
      cursor: pointer;
      &:focus {
        outline: none;
      }
      a,
      button {
        text-decoration: none;
        position: relative;
        display: block;
        padding: 0.5rem;
        margin: 0 3px;
        line-height: 1.25;
        background-color: transparent;
        color: ${color.border};
        font-weight: 500;
        transition: all 0.2s;
        /* border-radius: 10px; */
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid ${color.line};
        &:hover {
          background-color: ${color.primary};
          color: ${color.light};
        }
      }
      &.rc-pagination-disabled {
        a,
        button {
          background-color: transparent;
          cursor: default;
        }
      }
      button {
        &:focus {
          outline: none;
        }
        &:before {
          content: "";
          width: 30px;
          height: 10px;
          display: inline-block;
        }
      }
      &.rc-pagination-item-active {
        a {
          color: ${color.primary};
          border: 1px solid ${color.primary};
          &:hover {
            background-color: ${color.primary};
            color: ${color.light};
          }
        }
      }
      &.rc-pagination-prev {
        button {
          margin: 0;
          margin-right: 3px;
          border: 1px solid ${color.line};
          &:before {
            background: url(${imgUrl.leftArrow}) no-repeat center/cover;
          }
          &:hover {
            background: transparent;
            &:before {
              /* background: url(${imgUrl.leftArrow}) no-repeat center/cover; */
            }
          }
        }
      }
      &.rc-pagination-prev.rc-pagination-disabled {
        button {
          &:hover {
            &:before {
              background: url(${imgUrl.leftArrow}) no-repeat center/cover;
            }
          }
          &:before {
            opacity: 0.4;
          }
        }
      }
      &.rc-pagination-next.rc-pagination-disabled {
        button {
          &:hover {
            &:before {
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
              opacity: 0.5;
            }
          }
          &:before {
            opacity: 0.4;
          }
        }
      }
      &.rc-pagination-next {
        button {
          /* border-radius: 10px; */
          margin: 0;
          margin-left: 3px;
          &:before {
            background: url(${imgUrl.rightArrow}) no-repeat center/cover;
          }
          &:hover {
            background: transparent;
            &:before {
              background: url(${imgUrl.rightArrow}) no-repeat center/cover;
            }
          }
        }
      }
      &.rc-pagination-jump-prev,
      &.rc-pagination-jump-next {
        button {
          &:before {
            content: "...";
          }
        }
      }
      &.rc-pagination-next,
      &.rc-pagination-prev {
        button {
          &:hover {
            border: 1px solid ${color.primary}!important;
          }
        }
      }
    }
  }
`;
