export const languages = [
  {
    lang: 'English',
    code: 'en',
  },
  {
    lang: 'မြန်မာစာ',
    code: 'my',
  },
]
