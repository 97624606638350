import styled from "styled-components";
import { fontSize, fontWeight, color, fontFamily } from "../../attribute";

export const StyledFieldWrapper = styled.div`
  position: relative;
  &.vlc_con {
    margin-bottom: 30px;
  }

  textarea {
    height: auto;
  }

  &[type="checkbox"] {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;

    .label_txt {
      position: absolute;
      left: 20px;
      margin: 0;
    }
  }

  .err_msg {
    position: absolute;
    top: 100%;
    min-height: 18px;
    /* margin-left: 10px; */
  }

  .label_txt {
    margin-right: 5px;
  }
`;

export const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledInputContainer = styled.div``;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px;
  /* padding: ${(props) =>
    props?.theme?.formVariable?.padding || "7px 15px"}; */
  font-size: ${(props) => fontSize[props?.size] || props.size || fontSize.md}px;
  font-weight: ${(props) =>
    fontWeight[props?.weight] || props?.weight || fontWeight.md};
  color: ${(props) =>
    color[props?.inputTxtColor] || props?.inputTxtColor || color.text};
  background-color: ${(props) =>
    color[props?.inputBgColor] || props?.inputBgColor || color.inputBgColor};
  background-clip: padding-box;
  border: ${(props) => props?.inputBorderWidth || 1}px solid
    ${(props) =>
      color?.[props?.inputBorderColor] ||
      props?.inputBorderColor ||
      color?.border};
  border-radius: ${(props) => props?.borderRadius || 50}px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
  font-family: ${fontFamily.Poppins};

  &.qty-input {
    border-width: 0;
    padding: 0;
    text-align: center;
  }

  &:disabled {
    background: ${color?.background};
  }

  &.error {
    border: 1px solid ${color?.danger};
  }

  &:focus {
    border-color: ${color?.borderColor};
  }

  &::placeholder {
    opacity: 0.5;
    font-weight: 400;
  }

  &[type="checkbox"] {
    cursor: pointer;
    padding: 0;
    appearance: none;
    background-color: ${color?.light || "#fff"};
    margin: 0;
    width: 15px;
    height: 15px;
    border: 1px solid ${color?.gray || "#DDDD"};

    display: grid;
    place-content: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      border-radius: 0.15rem;
      width: 15px;
      height: 15px;
    }
  }

  &[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: -3px;
    bottom: 0;
    margin: auto;
    left: 5px;
    width: 5px;
    height: 10px;
    border: 1px solid ${color?.dark || "#000"};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
