import { routes } from "./routes";
import { http } from "./http";
import { store } from "../../services";
import theme from "../actions/theme";

export const getEndpointData = (endpointKey, params = {}) => {
  const endpoint = routes[endpointKey].endpoint;
  let url = endpoint;

  //replace the endpoint with responding keys
  for (const [key, value] of Object.entries(params)) {
    url = url.replace(`:${key}`, value);
  }

  let data = {
    endpoint: url,
    method: routes[endpointKey].method,
  };

  if (routes[endpointKey].access_token) {
    data.access_token = routes[endpointKey].access_token;
  }

  //return all api info (method,endpointurl,access-token status)
  return data;
};

export const apiHandler = (endpointKey, data, params = {}, access = null) => {

  let endpoint_data = getEndpointData(endpointKey, params && params);
  let url = endpoint_data.endpoint;
  let method = endpoint_data.method;
  if (access) {
    url = url + "/" + access;
  }

  //method and api endpoint
  let req_obj = {
    method: method,
    url: url,
  };

  //access_token
  if (endpoint_data?.access_token) {
    let access_token = getToken();
    req_obj.headers = {
      "access-token": access_token,
      "Content-Type": url ==="password/change" ?"application/json": "text/plain",
    };
  }

  //payload data
  if (data) {
    method === "get"
      ? url === "auth/token"
        ? (req_obj.headers = data)
        : (req_obj.params = data)
      : (req_obj.data = data);
  }

  //api fetch
  let res = http(req_obj);

  // --------- error handler start ------------
  res
    .then((result) => {
      if (result.status === "fail") {
        let final_data = {
          alway: true,
          type: "error",
          title: result?.error || "Something wrong! Please try again.",
          placement: "bottom",
          toastModalVisible: true,
        };
        store.dispatch(theme.setToast(final_data));
      }
    })
    .catch((error) => {
      console.log(error);
    });
  // --------- error handler end --------------

  return res;
};

const getToken = () => {
  // get access token from auth state
  let access_token = null;
  let auth = store.getState().auth;
  if (auth?.login_data) {
    access_token = auth?.login_data?.access_token[0]?.token;
  }
  // let access_token = '12345'
  return access_token;
};

//note
// if post method, pass the data as=> data:{id:1,lang:en}
// if get method, pass the data as=> params:{id:1,lang:en}
// in post method, there should be no queryString, only json data will be passed
