import styled from "styled-components";
import { color } from "../../attribute";

export const StyledSliderCom = styled.div`
    .swiper-button-next:after{
        font-size: 25px;
        color: ${color.text};
    }

    .swiper-button-prev:after{
        font-size: 25px;
        color: ${color.text};
    }

    @media (max-width: 425px){
        .swiper-button-next:after{
            font-size: 20px;
            margin-top: -15px;
        }
    
        .swiper-button-prev:after{
            font-size: 20px;
            margin-top: -15px;
        }
    }
`